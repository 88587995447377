import * as React from 'react'
import styled from 'styled-components'

import { t } from '@owl-nest/localize'
import * as entities from '@ulule/entities'
import * as plume from '@ulule/owl-kit-components/next'

import * as style from '../style'

type CategoriesProps = {
  categories: entities.homepage.Category[]
}

export function Categories({ categories }: CategoriesProps): React.ReactElement {
  return (
    <Section>
      <plume.styles.heading.M>{t('Browse by category')}</plume.styles.heading.M>
      <CategoryList>
        {categories.map((category) => (
          <li key={category.absolute_url}>
            <Category href={category.absolute_url}>
              <plume.glyphs.stroke.Tag size={12} />
              <plume.styles.copy.Base>{category.name}</plume.styles.copy.Base>
            </Category>
          </li>
        ))}
      </CategoryList>
    </Section>
  )
}

const Section = styled(style.Section)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1330px;
  padding: 0;
  width: 89%;

  ${plume.styles.heading.M} {
    margin-bottom: 20px;
  }
`

const Category = styled.a`
  border-radius: 19px;
  border: 1px solid ${plume.COLORS.PRIMARY_SAND_200};
  margin-right: 8px;
  margin-bottom: 14px;
  height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  text-decoration: none;

  ${plume.glyphs.stroke.Tag} {
    margin-right: 8px;
    color: ${plume.COLORS.PRIMARY_SAND_500};
  }

  ${plume.styles.copy.Base} {
    font-weight: 600; // HACK: irregular font manipulation
    color: ${plume.COLORS.PRIMARY_GREY_700};
  }

  &:hover {
    border-color: ${plume.COLORS.PRIMARY_BLUE_700};

    ${plume.styles.copy.Base}, ${plume.glyphs.stroke.Tag} {
      color: ${plume.COLORS.PRIMARY_BLUE_700};
    }
  }
`

const CategoryList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'natural-materials-boutique-illustration-icon'

export function NaturalMaterialsSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="M60.104 51.297c-7.417 8.752-21.171 10.728-31.992 17.575-.526.333-1.175-.02-1.029-.617 1.439-5.856 10.22-19.857 20.424-28.814.652-.572.06-1.584-.73-1.21-8.369 3.962-14.866 11.543-23.198 26.1-.305.533-1.097.517-1.355-.04-8.086-17.406-.436-35.716 17.067-39.659 8.351-1.88 16.535-3.98 24.76-7.462 1.391-.588 2.972.423 2.949 1.905-.188 11.997.397 23.62-6.896 32.222z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M55.138 48.742c-7.396 9.52-21.112 11.67-31.902 19.118-.525.363-1.172-.022-1.026-.67 1.434-6.37 10.191-21.602 20.366-31.345.65-.623.06-1.723-.727-1.317-8.346 4.31-15.822 10.397-24.13 26.233-.304.58-1.094.562-1.352-.043-4.861-9.245-3.403-34.445 15.557-38.864 24.316-5.666 23.782-5.7 29.11-7.813.48-.172.972.223.966.774-.145 12.65.201 24.84-6.862 33.928z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const NaturalMaterialsStandaloneContent = standalone(NaturalMaterialsSymbol, ID)

export const NaturalMaterials = styled(function NaturalMaterialsStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="natural-materials">
      <NaturalMaterialsStandaloneContent />
    </illustration.Illustration>
  )
})``

export const NaturalMaterialsLightweight = styled(function NaturalMaterialsComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="natural-materials">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'bulb-illustration-icon'

export function BulbSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 29 29">
      <g fill="none">
        <path
          d="M17.9729 5.10172C13.7874 4.62255 9.9818 7.43735 9.47268 11.3887C9.21478 13.3903 10.0177 14.9903 11.0881 16.7583C12.6055 19.2645 12.2915 22.0989 12.2915 22.0989L19.2019 22.89C19.2019 22.89 19.6169 20.0672 21.7266 17.9762C23.2147 16.5011 24.4006 15.1288 24.6585 13.1272C25.1676 9.17585 22.7131 5.64439 17.9731 5.10174L17.9729 5.10172Z"
          fill={'currentColor'}
          stroke={'currentColor'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5312 24.4436L12.4391 25.1588C12.4039 25.4323 12.6365 25.684 12.9585 25.7208L17.6402 26.2568C17.9623 26.2937 18.2521 26.1018 18.2873 25.8284L18.3795 25.1131L12.5312 24.4436Z"
          fill={'currentColor'}
          stroke={'currentColor'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2031 22.1096L11.9984 23.6987C11.9514 24.0635 12.2253 24.3948 12.6103 24.4388L18.3008 25.0903C18.6857 25.1344 19.0358 24.8745 19.0828 24.5097L19.2875 22.9207L12.2031 22.1096Z"
          fill={'currentColor'}
          stroke={'currentColor'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.08 22.4298C14.08 22.4298 16.3642 13.5946 14.9679 12.858C14.0386 12.3678 13.3049 14.0073 14.9254 14.3614C15.8327 14.5597 16.7789 14.2241 17.3433 13.5214C17.8795 12.8539 18.4108 11.5775 17.5941 11.2814C15.9505 10.6854 15.1484 15.1257 18.8441 15.3511C22.5399 15.5765 20.7889 12.8309 19.6616 13.8413C18.5343 14.8517 16.421 17.7208 17.4945 22.619"
          stroke={'currentColor'}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M13.8528 5.82532C9.63654 5.82532 6.21875 9.05207 6.21875 13.0325C6.21875 15.0487 7.22043 16.5459 8.50921 18.1793C10.3361 20.4948 10.3869 23.3442 10.3869 23.3442H17.3482C17.3482 23.3442 17.399 20.4948 19.2259 18.1793C20.5145 16.5459 21.5162 15.0487 21.5162 13.0325C21.5162 9.05207 18.6279 5.82532 13.853 5.82532H13.8528Z"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8594 25.7208V26.7668C10.8594 27.1667 11.1356 27.4911 11.4761 27.4911H16.4274C16.7681 27.4911 17.0444 27.1667 17.0444 26.7668V25.7208H10.8594Z"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2969 23.3646V24.9653C10.2969 25.3329 10.6112 25.6306 10.999 25.6306H16.7314C17.119 25.6306 17.4333 25.3329 17.4333 24.9653V23.3646H10.2969Z"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2037 23.4687C12.2037 23.4687 13.3411 14.4384 11.8608 13.8658C10.8756 13.4848 10.3569 15.1957 12.0109 15.3631C12.9369 15.4569 13.8334 15.0161 14.3038 14.2544C14.7507 13.5308 15.1149 12.2034 14.2662 12.0022C12.5583 11.5974 12.33 16.0965 16.0276 15.9002C19.7252 15.7039 17.6359 13.1774 16.646 14.3085C15.6562 15.4397 13.9253 18.528 15.6175 23.2685"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M13.9219 0.759155V3.52799"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M4.22266 4.52332L6.28852 6.48128"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M0.875 12.8127H3.79665"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24.0385 4.52356L21.9727 6.48153"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M27.3826 12.8129H24.4609"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </symbol>
  )
}

const BulbStandaloneContent = standalone(BulbSymbol, ID)

export const Bulb = styled(function BulbStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="bulb">
      <BulbStandaloneContent />
    </illustration.Illustration>
  )
})``

export const BulbLightweight = styled(function BulbComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="bulb">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

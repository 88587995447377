/* eslint-disable react/display-name */

import * as React from 'react'

import * as plume from '@ulule/owl-kit-components/next'
import * as richtext from '@owl-nest/rich-text'

export type RichTextProps<ENTRY extends { sys: { id: string } }> = richtext.RichTextProps<ENTRY>

export function RichText<ENTRY extends { sys: { id: string } }>({
  document,
  links,
  renderEntry,
  renderMark: {
    Bold = plume.styles.wysiwyg.Bold,
    Italic = plume.styles.wysiwyg.Italic,
    Underline = plume.styles.wysiwyg.Underline,
  } = {},
  renderNode: {
    Hyperlink = ({ children, uri }: { children: React.ReactNode; uri: string }) => (
      <plume.styles.wysiwyg.Hyperlink href={uri}>{children}</plume.styles.wysiwyg.Hyperlink>
    ),
    Paragraph = plume.styles.wysiwyg.Paragraph,
    Title1 = plume.styles.wysiwyg.Title,
    Title2 = plume.styles.wysiwyg.Title2,
    Title3 = plume.styles.wysiwyg.Title3,
    Title4 = plume.styles.wysiwyg.Title4,
    Title5 = plume.styles.wysiwyg.Title5,
    Title6 = plume.styles.wysiwyg.Title6,
    UnorderedList = plume.styles.wysiwyg.UnorderedList,
    OrderedList = plume.styles.wysiwyg.OrderedList,
    ListItem = plume.styles.wysiwyg.ListItem,
    BlockQuote = plume.styles.wysiwyg.BlockQuote,
  } = {},
}: RichTextProps<ENTRY>): React.ReactElement<RichTextProps<ENTRY>> {
  return (
    <richtext.RichText
      document={document}
      links={links}
      renderEntry={renderEntry}
      renderMark={{ Bold, Italic, Underline }}
      renderNode={{
        Hyperlink,
        Paragraph,
        Title1,
        Title2: Title2,
        Title3: Title3,
        Title4: Title4,
        Title5: Title5,
        Title6: Title6,
        UnorderedList,
        OrderedList,
        ListItem,
        BlockQuote,
      }}
    />
  )
}

import type * as api from '@owl-nest/api-client/latest'
import { lens } from '@owl-nest/config'

type Environment = {
  CURRENCIES: {
    CURRENT: string
    RATES: Record<string, number>
  }
  UFE: {
    defaultLocale: api.Lang
    locale: api.Lang
    user: api.AuthenticatedUser & {
      is_authenticated: boolean
      nb_projects: number
      project_list_url?: string
      settings_url?: string
      transactions_url: string
    }
  }
}

const windowLens = lens<Environment>()

const global =
  typeof process !== 'undefined'
    ? {
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
      }
    : window

export const CURRENCIES = windowLens.get('CURRENCIES').value(global, { trust: true })

export const DEFAULT_LOCALE = windowLens.get('UFE').get('defaultLocale').value(global)

export const RATES = windowLens.get('CURRENCIES').get('RATES').value(global, { trust: true })

export const USER = windowLens.get('UFE').get('user').value(global)

export const USER_LOCALE = windowLens.get('UFE').get('locale').value(global, { forgive: true, silent: true })

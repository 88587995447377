import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'instagram-illustration-icon'

export function InstagramSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 90 80">
      <g>
        <path
          d="M66.6 70.1H33.4c-10.1 0-18.2-8.2-18.2-18.2V18.7C15.2 8.6 23.4.5 33.4.5h33.2c10.1 0 18.2 8.2 18.2 18.2v33.2c0 10-8.2 18.2-18.2 18.2z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
        />
        <path
          d="M57.9 79.5H25.2c-11 0-20-9-20-20V26.9c0-11 9-20 20-20h32.6c11 0 20 9 20 20v32.6c.1 11.1-8.9 20-19.9 20zM25.2 9.9c-9.4 0-17 7.6-17 17v32.6c0 9.4 7.6 17 17 17h32.6c9.4 0 17-7.6 17-17V26.9c0-9.4-7.6-17-17-17H25.2z"
          fill={colors.PRIMARY_GREY_900}
        />
        <path
          d="M41.6 60.9C31.9 60.9 24 53 24 43.3c0-9.7 7.9-17.6 17.6-17.6s17.6 7.9 17.6 17.6c.1 9.7-7.9 17.6-17.6 17.6zm0-32.2c-8 0-14.6 6.5-14.6 14.6 0 8 6.5 14.6 14.6 14.6s14.6-6.5 14.6-14.6-6.5-14.6-14.6-14.6z"
          fill={colors.PRIMARY_GREY_900}
        />
        <circle cx="62" cy="22.8" r="4.6" fillRule="evenodd" clipRule="evenodd" fill={colors.PRIMARY_GREY_900} />
      </g>
    </symbol>
  )
}

const InstagramStandaloneContent = standalone(InstagramSymbol, ID)

export const Instagram = styled(function InstagramStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="instagram">
      <InstagramStandaloneContent />
    </illustration.Illustration>
  )
})`
  fill: none;
`

export const InstagramLightweight = styled(function InstagramComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="instagram">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})`
  fill: none;
`

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'note-illustration-icon'

export function NoteSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 31 28">
      <g clipPath="url(#a)">
        <mask
          id="b"
          width={31}
          height={28}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill={colors.PRIMARY_GREY_000} d="M30.95 0H.05v28h30.9V0Z" />
        </mask>
        <g mask="url(#b)">
          <path
            fill={colors.PRIMARY_BLUE_500}
            fillOpacity={0.3}
            d="M20.21 8.98c.36-2.24 2.53-3.75 4.83-3.4 1.28.2 2.26.8 2.87 1.66.61.85.84 1.93.66 3.03-.19 1.16-.89 1.92-1.68 2.66-.51.48-.83 1.06-1.02 1.53-.1.23-.16.44-.2.58 0 .03-.01.05-.02.07.02.05.02.1.01.15l-.13.82c-.04.24-.2.43-.4.52l-.02.15c-.06.37-.42.54-.72.49l-1.82-.28c-.03.69-.08 1.44-.12 2.22-.16 2.89-.38 6.16-.61 7.54a.39.39 0 0 1-.17.26c-.09.06-.2.08-.31.06l-1.96-.44c-.07.18-.13.33-.17.45l-.09.23-.02.06v.02c-.07.19-.28.29-.48.24l-2.23-.59-.26.44c-.06.11-.11.19-.15.25l-.04.06v.02h-.01c-.09.15-.27.22-.45.18L3.41 24.77a.42.42 0 0 1-.28-.27c-.04-.13 0-.27.09-.38.7-.77 1.55-2.17 2.42-3.85a85.41 85.41 0 0 0 2.46-5.23l1.93-4.81c.07-.19.28-.29.48-.24l1.65.44 9.18 2.42c-.08-.28-.18-.58-.34-.86-.51-.95-.95-1.88-.76-3.04l-.03.03Z"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.93 13.7h3.54s.03-1.48.96-2.69c.66-.85 1.17-1.63 1.17-2.68 0-2.07-1.47-3.76-3.9-3.76-2.15 0-3.89 1.68-3.89 3.76 0 .43.09.82.23 1.18M21.17 14.94v.82c0 .31.14.57.31.57H24c.17 0 .31-.25.31-.57v-.82h-3.15.01ZM20.89 13.71v.83c0 .19.16.35.36.35h2.92c.2 0 .36-.16.36-.35v-.83H20.89Z"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M22.73 1.57v1.44M17.44 3.54l1.05 1.02M28.23 3.54l-1.05 1.02M29.58 8.21h-1.49"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M21.5 11.47s-.46 11.03-.93 13.95l-2.38-.54"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m15.23 25.37 2.47.66s2.9-7.3 3.87-14.54"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M6.82 13.09c-1.57 3.65-3.69 8.15-5.25 9.89l12.81 3.43s4.73-7.86 7.17-15.11L8.87 7.9l-2.05 5.18v.01Z"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.73 9.37c.05-.35.11-.7.26-1.01.16-.31.43-.59.78-.65.35-.07.75.13.84.47M12.97 9.97c.05-.35.11-.7.26-1.01.16-.31.43-.59.78-.65.35-.07.75.13.84.47M15.42 10.63c.05-.35.11-.7.26-1.01.16-.31.43-.59.78-.65.35-.07.75.13.84.47M17.94 11.3c.05-.35.11-.7.26-1.01.16-.31.43-.59.78-.65.35-.07.87.18.87.82"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m8.85 12.97.55.99s1.11-.92 2.19-1.31M7.47 16.18l.55.99s1.11-.92 2.19-1.31M6.04 19.62l.55.99s1.11-.92 2.19-1.31"
          />
          <path
            stroke={colors.PRIMARY_GREY_900}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.85 12.97.55.99s1.11-.92 2.19-1.31M7.47 16.18l.55.99s1.11-.92 2.19-1.31M6.04 19.62l.55.99s1.11-.92 2.19-1.31"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill={colors.PRIMARY_GREY_000} d="M0 0h31v28H0z" />
        </clipPath>
      </defs>
    </symbol>
  )
}

const NoteStandaloneContent = standalone(NoteSymbol, ID)

export const Note = styled(function NoteStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="note">
      <NoteStandaloneContent />
    </illustration.Illustration>
  )
})`
  fill: none;
`

export const NoteLightweight = styled(function NoteComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="note">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})`
  fill: none;
`

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'circular-economy-boutique-illustration-icon'

export function CircularEconomySymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m30.638 48.054 3.88-4.301c.812-.755.48-1.438-.566-1.438h-2.747v-.417c.156-5.984 2.825-10.621 6.706-13.404 5.415-3.965 13.01-4.21 18.504.337C44.616 14.775 19.64 23.957 19.64 42.325h-2.658c-1.046 0-1.29.672-.567 1.437l3.88 4.301 3.881 4.301c.724.846 1.858.846 2.669 0l3.793-4.3v-.011zM66.585 43.225l-3.88-4.296-3.801-4.295c-.811-.845-1.935-.845-2.668 0l-3.88 4.295-3.88 4.296c-.723.845-.488 1.435.567 1.435h2.746v.418c-.078 5.975-2.824 10.607-6.704 13.386-5.336 3.96-12.93 4.296-18.5-.336 11.874 14.14 36.844 4.886 36.844-13.468h2.59c1.045 0 1.29-.59.566-1.435z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="m28.638 44.054 3.88-4.301c.812-.755.48-1.438-.567-1.438h-2.746v-.417c.156-5.984 2.825-10.621 6.706-13.404 5.415-3.965 13.01-4.21 18.504.337C42.616 10.775 17.64 19.957 17.64 38.325h-2.658c-1.046 0-1.29.672-.567 1.437l3.88 4.301 3.881 4.301c.724.846 1.858.846 2.669 0l3.793-4.3v-.011zM64.585 39.225l-3.88-4.296-3.801-4.295c-.811-.845-1.935-.845-2.668 0l-3.88 4.295-3.88 4.296c-.723.845-.488 1.435.567 1.435h2.746v.418c-.078 5.975-2.824 10.607-6.704 13.386-5.336 3.96-12.93 4.296-18.5-.336 11.874 14.14 36.844 4.886 36.844-13.468h2.59c1.045 0 1.29-.59.566-1.435z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const CircularEconomyStandaloneContent = standalone(CircularEconomySymbol, ID)

export const CircularEconomy = styled(function CircularEconomyStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="circular-economy">
      <CircularEconomyStandaloneContent />
    </illustration.Illustration>
  )
})``

export const CircularEconomyLightweight = styled(function CircularEconomyComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="circular-economy">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled, { css } from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import * as hooks from '@owl-nest/hooks'
import { t, tc, tn } from '@owl-nest/localize'
import * as models from '@owl-nest/models'
import * as services from '@owl-nest/services'
import { usePartnershipTracker } from '@owl-nest/shadow'
import * as utils from '@owl-nest/utils'

import * as duvet from '@ulule/duvet'
import * as entities from '@ulule/entities'
import * as layout from '@ulule/layout'
import * as plume from '@ulule/owl-kit-components/next'

import * as UFE from '../UFE'

import { Carousel, CarouselWrapper, ControlButtons } from '../components/Carousel'

import * as style from '../style'

type ProjectsSectionProps = {
  categories: entities.homepage.Category[]
  featuredOperation: entities.homepage.FeaturedOperation | null
}

const DISPLAY_COUNT = 12
const MOBILE_DISPLAY_COUNT = 4
const SKELETON_LIST = [...new Array(DISPLAY_COUNT)]
const FALLBACK_CURRENCY_CODE = 'EUR'

export function Projects({ categories, featuredOperation }: ProjectsSectionProps): React.ReactElement {
  const auth = services.user.useAuth()

  const router = hooks.useNextRouter()
  const userLanguage = router.locale || router.defaultLocale
  const initialSort = userLanguage === 'fr' ? 'staff-pick' : 'popular'

  const currencyCode = typeof router.query.currency_code === 'string' ? router.query.currency_code : undefined
  const targetCurrency = currencyCode ?? FALLBACK_CURRENCY_CODE

  const projectSearch = services.search.project.useSearch({
    fetchOnMount: false,
    options: {
      withAccessToken: auth.type === 'loggedin' || UFE.USER.is_authenticated,
    },
    params: {
      lang: userLanguage,
      limit: `${DISPLAY_COUNT + 1}`,
    },
    qualifiers: {
      sort: initialSort,
    },
  })

  React.useEffect(() => {
    if (auth.type === 'initial') {
      return
    }

    projectSearch.query({
      sort: activeFilter.sort,
      ...(activeFilter.category && { tag_id: activeFilter.category }),
    })
  }, [auth.type])

  const filters = React.useMemo(() => getFilters(categories, userLanguage), [])
  const [activeFilter, setActiveFilter] = React.useState(filters[0])
  const discoverURL = React.useMemo(() => getDiscoverURL(activeFilter), [activeFilter])

  const [isFollowModalOpen, setFollowModalAsOpen] = React.useState(false)

  const [isDisplayingProjects, setIsDisplayingProjects] = React.useState(false)

  const debouncedSetIsDisplayingProjects = plume.hooks.useDebounce(
    () => {
      setIsDisplayingProjects(true)
    },
    false,
    300,
  )

  React.useEffect(() => {
    if (projectSearch.response.status === hooks.QueryStatus.SUCCESS) {
      debouncedSetIsDisplayingProjects()
    }
  }, [projectSearch.response.status])

  const shouldDisplayFeaturedSection = activeFilter.id === initialSort

  const projects = projectSearch.response.data?.projects
  const displayedList = shouldDisplayFeaturedSection ? projects?.slice(1) : projects?.slice(0, projects.length - 1)

  const featuredProject = projects?.[0]
  const featuredProjectImage = featuredProject && duvet.projectCard.utils.getProjectPictures(featuredProject)

  const featuredOperationRef = React.useRef<HTMLAnchorElement>(null)

  usePartnershipTracker(featuredOperation?.partnerUserId ?? undefined, featuredOperationRef, {
    click: {
      enabled: true,
    },
    impression: {
      enabled: true,
      once: true,
    },
  })

  return (
    <>
      <WideSection>
        <style.TitleGroup>
          <style.SectionTitle>
            {tc('[highlight: Projects] in progress', {
              highlight: <plume.HighlightedTitle color="blue" />,
            })}
          </style.SectionTitle>
        </style.TitleGroup>
        <TabMenuWrapper>
          <plume.TabMenu>
            {filters
              .filter((filter) => filter.id !== undefined)
              .map((filter, index) => (
                <plume.Tab
                  active={activeFilter.id === filter.id}
                  key={`${filter.label}:${index}`}
                  onClick={() => {
                    if (activeFilter.label !== filter.label && auth.type !== 'initial') {
                      setIsDisplayingProjects(false)
                      setActiveFilter(filter)
                      projectSearch.query({
                        sort: filter.sort,
                        ...(filter.category && { tag_id: filter.category }),
                      })
                    }
                  }}
                >
                  <plume.styles.copy.S>{filter.label}</plume.styles.copy.S>
                </plume.Tab>
              ))}
          </plume.TabMenu>
        </TabMenuWrapper>
        <Carousel $verticalOnMobile>
          <>
            {shouldDisplayFeaturedSection && (
              <FeaturedElements>
                {isDisplayingProjects && featuredProject ? (
                  <FeaturedCardTypeSelector
                    action={getAction(featuredProject)}
                    cardType="next"
                    data-project-id={featuredProject.id}
                    data-selected-project={true}
                    fallbackLang={router.defaultLocale}
                    highlightWhenFeatured={false}
                    key={featuredProject.id}
                    lang={userLanguage}
                    project={featuredProject}
                    projectTranslation={getProjectCardTranslation(featuredProject)}
                    rates={UFE.RATES}
                    size="large"
                    targetCurrency={targetCurrency}
                  />
                ) : (
                  <plume.ProjectCardSkeleton
                    size="large"
                    picture={featuredProjectImage?.picture}
                    srcSet={featuredProjectImage?.srcSet}
                  />
                )}

                {featuredOperation && (
                  <FeaturedPartnerLink href={featuredOperation.ctaUrl} ref={featuredOperationRef}>
                    <FeaturedPartnerOperation>
                      <CallToActionSection>
                        <plume.glyphs.stroke.Megaphone />
                        <plume.styles.heading.XXXS>{featuredOperation.title}</plume.styles.heading.XXXS>
                        <plume.RoundButton
                          contents={{
                            active: (
                              <>
                                {featuredOperation.ctaLabel ?? t('Apply')}
                                {'\u00A0'}
                                {<plume.glyphs.stroke.ArrowRight />}
                              </>
                            ),
                            inactive: (
                              <>
                                {featuredOperation.ctaLabel ?? t('Apply')}
                                {'\u00A0'}
                                {<plume.glyphs.stroke.ArrowRight />}
                              </>
                            ),
                          }}
                          noShadow
                        />
                      </CallToActionSection>
                      <CallToActionImage alt={featuredOperation.title} src={featuredOperation.image.src} />
                    </FeaturedPartnerOperation>
                  </FeaturedPartnerLink>
                )}
              </FeaturedElements>
            )}
            <ListPositioner>
              <CardsWrapper>
                {isDisplayingProjects && displayedList
                  ? displayedList.map((project, index) => {
                      return (
                        <ProjectCard
                          $hideOnMobile={index >= MOBILE_DISPLAY_COUNT}
                          action={getAction(project)}
                          cardType="next"
                          data-project-id={project.id}
                          fallbackLang={router.defaultLocale}
                          highlightWhenFeatured={false}
                          key={project.id}
                          lang={userLanguage}
                          project={project}
                          projectTranslation={getProjectCardTranslation(project)}
                          rates={UFE.RATES}
                          targetCurrency={targetCurrency}
                        />
                      )
                    })
                  : SKELETON_LIST.map((_, index) => (
                      <ProjectCardSkeleton $hideOnMobile={index >= MOBILE_DISPLAY_COUNT} key={index} />
                    ))}
              </CardsWrapper>
            </ListPositioner>
          </>
        </Carousel>

        <style.CenteredCTA href={discoverURL}>{t('See all projects')}</style.CenteredCTA>
      </WideSection>
      <layout.modals.FollowProject isOpen={isFollowModalOpen} onClose={() => setFollowModalAsOpen(false)} />
    </>
  )

  function getAction(project: api.Project) {
    return {
      isCompact: true,
      ...duvet.projectCard.getAction(project, async (userIsFollowingProject) => {
        if (auth.type === 'loggedin') {
          if (!userIsFollowingProject) {
            await api.api.post.likeProject({
              urlParams: { projectId: String(project.id) },
            })
            projectSearch.mutate({
              ...project,
              user_role: api.UserRole.Fan,
            })
            setFollowModalAsOpen(true)
          } else {
            await api.api.post.unlikeProject({
              urlParams: { projectId: String(project.id) },
            })
            projectSearch.mutate({
              ...project,
              user_role: undefined,
            })
          }
        }
      }),
    }
  }
}

// Corresponds to: 1 * [featured project card = 450] + 6 * [project card = 280] + 6 * [gap = 16] + 2 * [gutter = 30]
const MAX_CAROUSEL_WIDTH = '2285px'

const TabMenuWrapper = styled.div`
  ${plume.TabMenu} {
    justify-content: left;
    max-width: 1330px; // TODO: Have it within a constant to sync with TitleGroup?
    z-index: 0;

    &::after {
      height: 42px;
      top: auto;
      right: 16px;
    }
  }

  margin: 0 16px 16px;
  width: auto;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    width: 89%;
    max-width: 1330px;

    ${plume.TabMenu} {
      margin: 0 auto 10px 0;

      &::after {
        right: calc(11% - 16px);
      }
    }
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    margin: 0 auto;

    ${plume.TabMenu} {
      &::after {
        right: 184px;
      }

      width: 86%;
    }
  }
`

const WideSection = styled(style.WideSection)`
  margin-top: 0;
  padding-top: 30px;

  ${CarouselWrapper} {
    padding-top: 20px;
  }

  ${ControlButtons} {
    display: none;
  }

  ${plume.Tab} {
    cursor: pointer;
    padding: 10px;

    ${plume.styles.copy.S} {
      font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.S.semiBold, so this is just by anticipation.
      width: max-content;
    }
  }

  ${style.TitleGroup} {
    margin: 0 16px 16px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${ControlButtons} {
      display: flex;
    }
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    margin: 0 auto 30px;
    padding-top: 50px;

    ${style.TitleGroup} {
      margin: 0 auto 10px;
    }
  }
`

/**
 * FEATURED ITEMS SECTION
 */

const CallToActionImage = styled.img`
  max-width: 128px;
  margin: auto;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin: 0 0 0 auto;
    max-height: 100%;
    max-width: 160px;
    position: relative;
    top: -7.5px;
  }
`

const CallToActionSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;

  ${plume.RoundButton} {
    color: ${plume.COLORS.PRIMARY_BLUE_700};
    margin-left: -10px;
    width: 100%;
    margin-top: 16px;
  }

  ${plume.glyphs.stroke.Megaphone} {
    position: absolute;
    color: ${plume.COLORS.PRIMARY_GREY_900};
  }

  ${plume.glyphs.stroke.ArrowRight} {
    color: ${plume.COLORS.PRIMARY_BLUE_700};
    margin-top: 5px;
    position: relative;
    right: 0;
    transition: right 0.3s cubic-bezier(0.83, 0, 0.17, 1);
  }

  ${plume.styles.heading.XXXS} {
    padding-left: 46px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin-top: 0;

    ${plume.styles.heading.XXXS} {
      padding-left: 0;
      max-width: 225px;
    }

    ${plume.glyphs.stroke.Megaphone} {
      position: relative;
      flex: none;
    }

    ${plume.RoundButton} {
      width: 162px;
      margin-top: 0;
    }
  }
`

const FeaturedElements = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${plume.ProjectCardSkeleton} {
    flex: none;
    margin: auto;
    min-width: 0;
    width: 310px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin-left: 30px;

    ${plume.ProjectCardSkeleton} {
      height: 345px;
      margin: revert;
      width: 100%;
    }
  }

  @media screen and (min-width: ${MAX_CAROUSEL_WIDTH}) {
    margin-left: auto;
  }
`

const FeaturedPartnerLink = styled.a`
  margin: auto;
  text-decoration: none;
  width: fit-content;

  &:hover {
    ${plume.glyphs.stroke.ArrowRight} {
      right: -8px;
    }
  }
`

const FeaturedPartnerOperation = styled.div`
  background-color: ${plume.COLORS.PRIMARY_SAND_100};
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  margin: 0 auto 16px;
  max-width: 310px;
  padding: 24px;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    flex-direction: row;
    height: 153px;
    margin: 0;
    max-width: revert;
    padding: 24px 24px 11px;
    width: 450px;
  }
`

const FeaturedCardTypeSelector = styled(duvet.CardTypeSelector)`
  display: flex;
  flex-shrink: 0;
  margin: 0 auto;
  max-width: 310px;
  width: 100%;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    height: 345px;
    margin: revert;
    max-width: 450px;
    min-width: revert;
    width: auto;
  }
`

/**
 * PROJECT LIST
 */

const ProjectCard = styled(duvet.CardTypeSelector)<{ $hideOnMobile?: boolean }>`
  display: flex;

  ${({ $hideOnMobile = false }) => {
    if ($hideOnMobile) {
      return css`
        @media not screen and ${plume.BREAKPOINTS.TABLET} {
          display: none;
        }
      `
    }
  }};
`

const ProjectCardSkeleton = styled(plume.ProjectCardSkeleton)<{ $hideOnMobile?: boolean }>`
  ${({ $hideOnMobile = false }) => {
    if ($hideOnMobile) {
      return css`
        @media not screen and ${plume.BREAKPOINTS.TABLET} {
          display: none;
        }
      `
    }
  }};
`

const CardsWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 310px;

  & > ${plume.ProjectCardSkeleton} {
    scroll-snap-align: center;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    grid-template-columns: repeat(6, 280px);
    grid-template-rows: 1fr 1fr;
    margin-left: 50px;
    margin-right: 30px;
    scroll-snap-type: x mandatory;
    width: 1760px;
  }

  @media screen and (min-width: ${MAX_CAROUSEL_WIDTH}) {
    margin-right: 0;
  }
`

const ListPositioner = styled.div`
  display: flex;
  justify-content: center;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${FeaturedElements} + & {
      ${CardsWrapper} {
        margin-left: 16px;
      }
    }
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    ${FeaturedElements} + & {
      margin-left: 0;
    }

    margin-left: auto;
    margin-right: auto;
  }
`

/**
 * HELPERS
 */

type Filter = {
  category?: number
  id: string
  label: string
  sort: api.SearchQualifier['sort']
}

function getDiscoverURL(filter: Filter): string {
  let url = `/discover?sort=${filter.sort}`

  if (filter.category) {
    url += `&categories=${filter.id}`
  }

  return url
}

function getFilters(categories: entities.homepage.Category[], language: api.Lang): Filter[] {
  const featuredCategories: Record<string, { id: number; name: string; slug: string }> = categories
    .filter((category) =>
      [
        'bandes-dessinees',
        'charities-citizen',
        'fashion-design',
        'games',
        'music',
        'publishing-journalism',
        'sante-bien-etre',
      ].includes(category.slug),
    )
    .reduce((accumulator, category) => {
      return {
        [category.slug]: {
          id: category.id,
          name: category.name,
          slug: category.slug,
        },
        ...accumulator,
      }
    }, {})

  const filters: Filter[] = [
    {
      id: 'staff-pick',
      label: t('Staff picks'),
      sort: 'staff-pick',
    },
    {
      id: 'popular',
      label: t('Popular'),
      sort: 'popular',
    },
    {
      category: featuredCategories['bandes-dessinees']?.id,
      id: featuredCategories['bandes-dessinees']?.slug,
      label: featuredCategories['bandes-dessinees']?.name,
      sort: 'popular',
    },
    {
      category: featuredCategories.games?.id,
      id: featuredCategories.games?.slug,
      label: featuredCategories.games?.name,
      sort: 'popular',
    },
    {
      category: featuredCategories.music?.id,
      id: featuredCategories.music?.slug,
      label: featuredCategories.music?.name,
      sort: 'popular',
    },
    {
      category: featuredCategories['fashion-design']?.id,
      id: featuredCategories['fashion-design']?.slug,
      label: featuredCategories['fashion-design']?.name,
      sort: 'popular',
    },
    {
      category: featuredCategories['sante-bien-etre']?.id,
      id: featuredCategories['sante-bien-etre']?.slug,
      label: featuredCategories['sante-bien-etre']?.name,
      sort: 'popular',
    },
    {
      category: featuredCategories['publishing-journalism']?.id,
      id: featuredCategories['publishing-journalism']?.slug,
      label: featuredCategories['publishing-journalism']?.name,
      sort: 'popular',
    },
    {
      category: featuredCategories['charities-citizen']?.id,
      id: featuredCategories['charities-citizen']?.slug,
      label: featuredCategories['charities-citizen']?.name,
      sort: 'popular',
    },
  ]

  // There are no staff picks for other than FR projects
  if (language !== 'fr') {
    filters.shift()
  }

  return filters
}

function getProjectCardTranslation(project: api.Project): plume.ProjectCardProps['translation'] {
  return {
    addToMyList: t('Add to my list'),
    backed: t('Backed'),
    backerCount: tn(
      '%s contribution',
      '%s contributions',
      project.supporters_count || 0,
      utils.text.numberToLocaleString(project.orders_count || project.supporters_count),
    ),
    comingSoon: t('Online shortly, sign up now!'),
    created: t('Created'),
    extraTime: t('Extension'),
    finished: t('Finished'),
    highlights: t('Highlights'),
    listed: t('Listed'),
    progressInfo: t('%(percent)s reached', {
      percent: `${utils.text.numberToLocaleString(models.project.progress(project))}%`,
    }),
    shortCountdown: models.project.getCountDown(project, true),
    status: models.project.getStatus(project),
  }
}

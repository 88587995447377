import * as React from 'react'
import * as jotai from 'jotai'

import * as api from '@owl-nest/api-client/latest'
import * as hooks from '@owl-nest/hooks'

import * as base from './base'

//TODO : store the project in each list in the `SHARED_PROJECT_CACHE`, and only
//store the ids in the list. That way mutating a project via this hook will
//update it for the `useProject` hook

const SHARED_PROJECT_SEARCH_CACHE = jotai.atom<hooks.Cache<api.Pageable<api.ProjectList>>>({})

export type UseSearch<EXTRA_FIELDS extends string> = {
  mutate: (project: api.Project<EXTRA_FIELDS>) => void
  response: hooks.Response<api.ProjectList<EXTRA_FIELDS>>
  query: (qualifiers?: api.SearchQualifier) => void
}

type ProjectSearchArgs = {
  fetchOnMount?: boolean | 'force'
  params: base.ProjectSearchParams
  options?: {
    withAccessToken?: boolean
  }
  qualifiers?: api.SearchQualifier
}

export function useSearch<EXTRA_FIELDS extends string = ''>({
  fetchOnMount = true,
  options,
  params,
  qualifiers,
}: ProjectSearchArgs): UseSearch<EXTRA_FIELDS> {
  const {
    getCurrentResponse,
    mutate: mutateProject,
    query: queryProjectSearch,
  } = hooks.useSharedQuery((params, qualifiers) => base.search(params, options, qualifiers), {
    atom: SHARED_PROJECT_SEARCH_CACHE,
    id,
  })

  hooks.useFetchOnMount({ fetchOnMount, query: () => query(qualifiers), getResponse }, id(params, qualifiers))

  return { mutate, response: getResponse(), query }

  function getResponse(): hooks.Response<api.ProjectList<any>> {
    const response = getCurrentResponse([params, qualifiers])

    if (response === undefined) {
      return { status: hooks.QueryStatus.PRISTINE }
    }

    return response
  }

  function id(params: base.ProjectSearchParams, _qualifiers?: api.SearchQualifier): string {
    return JSON.stringify([params.lang, params.limit])
  }

  function mutate(updatedProject: api.Project<EXTRA_FIELDS>): void {
    return mutateProject([params, qualifiers], (response) => {
      const currentData = hooks.data(response)

      if (currentData === undefined) {
        return response
      }

      const projectIndex = currentData.projects.findIndex((project) => {
        return project.id === updatedProject.id
      })

      currentData.projects[projectIndex] = updatedProject

      return {
        data: { ...currentData },
        status: hooks.QueryStatus.SUCCESS,
      }
    })
  }

  // FIXME: query should not take parameters
  function query(qualifiers?: api.SearchQualifier): Promise<void> {
    return queryProjectSearch(params, qualifiers)
  }
}

import * as React from 'react'
import styled from 'styled-components'

import { useScrollableListener, useScrollListener } from '../hooks'
import * as S from '../styles'

export type TabProps = {
  className?: string
  children: React.ReactNode
  /** @default false */
  active?: boolean
  /** @default false */
  disabled?: boolean
} & React.HTMLAttributes<HTMLLIElement>

function TabComponent({
  className,
  children,
  active = false,
  disabled = false,
  ...props
}: TabProps): React.ReactElement<TabProps> {
  return (
    <S.tab.Item active={active} className={className} disabled={disabled} {...props}>
      {children}
    </S.tab.Item>
  )
}

export const TabMenu = styled(TabMenuComponent)<TabMenuProps>``
TabMenu.displayName = 'TabMenu'

type TabMenuProps = {
  className?: string
  children: React.ReactNode
} & React.HTMLAttributes<HTMLUListElement>

function TabMenuComponent({ className, children, ...props }: TabMenuProps): React.ReactElement<TabMenuProps> {
  const scrollerRef = React.useRef<HTMLUListElement>(null)
  const [isScrollable, setIsScrollable] = React.useState(false)
  useScrollableListener(scrollerRef, (scrollable) => {
    setIsScrollable(scrollable.x)
  })

  const [shadowWidth, setShadowWidth] = React.useState(60)
  useScrollListener(scrollerRef, ({ x, dx }) => {
    if (scrollerRef.current === null) {
      return
    }
    const scrollableWidth = scrollerRef.current.scrollWidth - scrollerRef.current.clientWidth
    if (x > scrollableWidth - 60) {
      setShadowWidth(scrollableWidth - x)
    } else {
      setShadowWidth(60)
    }
  })

  return (
    <S.tab.List className={className} shadowWidth={shadowWidth} scrollable={isScrollable} ref={scrollerRef} {...props}>
      {children}
    </S.tab.List>
  )
}

export const Tab = styled(TabComponent)<TabProps>``
Tab.displayName = 'Tab'

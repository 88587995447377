import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'organic-boutique-illustration-icon'

export function OrganicSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="M27.983 16s0 12.508 12.509 12.508C40.492 16 27.983 16 27.983 16z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M40.492 28.508C53 28.508 53 16 53 16s-12.508 0-12.508 12.508z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M24.231 21.003s0 13.76 13.76 13.76c0-13.76-13.76-13.76-13.76-13.76z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M37.99 34.763c13.76 0 13.76-13.76 13.76-13.76s-13.76 0-13.76 13.76z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M30.172 45.62c0-3.904-2.66-6.348-6.842-6.348H18a4 4 0 0 0-4 4V58.66a4 4 0 0 0 4 4h6.056c2.246 0 4.008-.68 5.356-2.037 1.348-1.358 2.039-3.022 2.039-4.99 0-1.12-.38-2.24-1.175-3.327-.795-1.086-1.866-1.8-3.248-2.139 2.211-.814 3.144-2.614 3.144-4.548zM18.423 43.14h4.458c1.693 0 2.695 1.053 2.695 2.716 0 1.63-1.002 2.818-2.557 2.818h-4.596V43.14zm5.287 9.166c1.935 0 3.11 1.595 3.11 3.259 0 1.731-1.175 3.225-3.006 3.225h-5.39v-6.484h5.286zM38.096 62.66a2.194 2.194 0 0 0 2.194-2.194v-19a2.194 2.194 0 1 0-4.389 0v19c0 1.212.983 2.194 2.195 2.194zM50.446 45.552c1.485-1.528 3.317-2.275 5.46-2.275 2.142 0 3.939.747 5.425 2.275 1.486 1.493 2.246 3.326 2.246 5.465s-.76 3.972-2.246 5.465c-1.486 1.494-3.283 2.24-5.426 2.24-2.142 0-3.974-.746-5.46-2.24-1.485-1.493-2.211-3.326-2.211-5.465s.726-3.972 2.212-5.465zm-6.6 5.465c0 3.293 1.174 6.144 3.49 8.486 2.35 2.343 5.217 3.497 8.57 3.497 3.351 0 6.22-1.154 8.57-3.496C66.825 57.16 68 54.31 68 51.017c0-3.327-1.175-6.144-3.525-8.487-2.315-2.342-5.183-3.53-8.57-3.53-3.386 0-6.254 1.188-8.57 3.53-2.315 2.343-3.49 5.16-3.49 8.487z"
          fill={colors.PRIMARY_BLACK}
        />
      </g>
    </symbol>
  )
}

const OrganicStandaloneContent = standalone(OrganicSymbol, ID)

export const Organic = styled(function OrganicStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="organic">
      <OrganicStandaloneContent />
    </illustration.Illustration>
  )
})``

export const OrganicLightweight = styled(function OrganicComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="organic">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'optimized-inventory-boutique-illustration-icon'

export function OptimizedInventorySymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <rect
          x="46"
          y="46.735"
          width="10.172"
          height="10.132"
          rx="2"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="2"
        />
        <rect
          x="46"
          y="56.868"
          width="10.172"
          height="10.132"
          rx="2"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="2"
        />
        <rect
          x="35.828"
          y="56.868"
          width="10.172"
          height="10.132"
          rx="2"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="2"
        />
        <path
          d="M43.336 16.38a4 4 0 0 1 5.328 0l16.688 14.903a10 10 0 0 1 3.339 7.458V67h-7.042V40.5H30.35V67H23.31V38.741a10 10 0 0 1 3.339-7.458l16.688-14.904z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <rect x="40" y="45.971" width="10.759" height="10.515" rx="2" stroke={colors.PRIMARY_BLACK} strokeWidth="3" />
        <rect x="40" y="56.485" width="10.759" height="10.515" rx="2" stroke={colors.PRIMARY_BLACK} strokeWidth="3" />
        <rect
          x="29.241"
          y="56.485"
          width="10.759"
          height="10.515"
          rx="2"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
        />
        <path
          d="M37.364 14.31a4 4 0 0 1 5.272 0L60.59 30.042a10 10 0 0 1 3.41 7.52V67h-7.448V39.5H23.448V67H16V37.563a10 10 0 0 1 3.41-7.521L37.364 14.31z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const OptimizedInventoryStandaloneContent = standalone(OptimizedInventorySymbol, ID)

export const OptimizedInventory = styled(function OptimizedInventoryStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="optimized-inventory">
      <OptimizedInventoryStandaloneContent />
    </illustration.Illustration>
  )
})``

export const OptimizedInventoryLightweight = styled(function OptimizedInventoryComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="optimized-inventory">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'sustainable-boutique-illustration-icon'

export function SustainableSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="M56.603 47.224c11.732 1.387 9.853 17.276-1.879 15.89-11.491-1.36-14.21-17.792-26.854-19.287-10.867-1.285-12.745 14.604-1.878 15.889 12.644 1.495 19.12-13.85 30.613-12.492h-.002z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.6 39c13.867 0 13.867 20 0 20-13.585 0-19.019-20-33.966-20-12.845 0-12.845 20 0 20 14.947 0 20.38-20 33.968-20h-.003z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 19.511s1.89 13.872 14.772 12.116C36.88 17.756 24 19.511 24 19.511z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M38.772 31.627C51.652 29.872 49.762 16 49.762 16s-12.881 1.755-10.99 15.627z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M28 22s0 14 13 14c0-14-13-14-13-14zM41 36c13 0 13-14 13-14s-13 0-13 14z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const SustainableStandaloneContent = standalone(SustainableSymbol, ID)

export const Sustainable = styled(function SustainableStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="sustainable">
      <SustainableStandaloneContent />
    </illustration.Illustration>
  )
})``

export const SustainableLightweight = styled(function SustainableComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="sustainable">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

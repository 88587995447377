import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'fair-trade-boutique-illustration-icon'

export function FairTradeSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="M50.468 43.58V35H40.454v10.353c-.453 5.15-2.95 8.626-7.454 8.626V63h25v-9.02c-5.005-.003-7.532-4.293-7.532-10.4zM12 12s0 10 10 10c0-10-10-10-10-10zM22 22c10 0 10-10 10-10s-10 0-10 10z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M9 16s0 11 11 11C20 16 9 16 9 16zM20 27c11 0 11-11 11-11s-11 0-11 11z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path
          d="M67 13.865a7.5 7.5 0 1 0 0 13.27"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M54 19h8M54 23h8"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.564 45.805V36H34.349v11.833C33.84 53.718 31.044 57.69 26 57.69V68h28V57.69c-5.606-.002-8.436-4.905-8.436-11.885z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
        <path d="M12 36h16M52 36h16" stroke={colors.PRIMARY_BLACK} strokeWidth="3" strokeLinecap="round" />
        <path
          d="M34 47H20V37M46 47h14V37"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const FairTradeStandaloneContent = standalone(FairTradeSymbol, ID)

export const FairTrade = styled(function FairTradeStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="fair-trade">
      <FairTradeStandaloneContent />
    </illustration.Illustration>
  )
})``

export const FairTradeLightweight = styled(function FairTradeComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="fair-trade">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled, { css } from 'styled-components'

import * as consent from '@owl-nest/consent'
import { t, tc } from '@owl-nest/localize'
import * as entities from '@ulule/entities'
import { RichText } from '@ulule/rich-text'
import * as plume from '@ulule/owl-kit-components/next'

import * as style from '../style'

type TrainingProps = {
  testimonial: entities.homepage.Homepage['testimonial']
  training: {
    organisation: entities.homepage.TrainingCourse[]
    particulier: entities.homepage.TrainingCourse[]
  } | null
}

export function Training({ testimonial, training }: TrainingProps): React.ReactElement {
  if (training === null) {
    return <></>
  }

  return (
    <>
      <WideSection>
        <style.TitleGroup>
          <style.SectionTitle>
            {tc('Our [highlight: training courses]: explore, progress', {
              highlight: <plume.HighlightedTitle color="red" />,
            })}
          </style.SectionTitle>
          <style.SectionSubTitle>
            {t('These trainings and skills assessments can be %(percent)s covered.', {
              percent: '100%',
            })}
          </style.SectionSubTitle>
        </style.TitleGroup>
        {testimonial && (
          <TestimonialAside>
            <VideoLoaderWrapper>
              {testimonial.video ? (
                <plume.VideoLoader
                  consentWall={consent.media.Wall}
                  placeholder={testimonial.image.src}
                  ratio={16 / 9}
                  srcSet={testimonial.image.srcSet}
                  videoHtml={testimonial.video}
                />
              ) : (
                <plume.Picture src={testimonial.image.src} srcSet={testimonial.image.srcSet} />
              )}
            </VideoLoaderWrapper>
            <Quote>
              <QuoteSymbol />
              <QuoteContent>{testimonial.quote}</QuoteContent>
              <QuoteSource as="div">
                <RichText
                  document={testimonial.source.json}
                  renderNode={{
                    Hyperlink: ({ children, uri }) => (
                      <plume.styles.wysiwyg.Hyperlink
                        href={`${uri}?utm_source=ulule&utm_medium=homepage&utm_campaign=featured_testimonial`}
                      >
                        {children}
                      </plume.styles.wysiwyg.Hyperlink>
                    ),
                  }}
                />
              </QuoteSource>
            </Quote>
          </TestimonialAside>
        )}
        <style.ContrastedSection>
          <Wrapper>
            {training.particulier.length > 0 && (
              <>
                <plume.styles.heading.XXXS
                  as="a"
                  asDesktop="XS"
                  href="https://formation.ulule.com?utm_source=ulule&utm_medium=homepage&utm_campaign=training_courses_for_individuals"
                >
                  {t('For individuals')}
                </plume.styles.heading.XXXS>
                <TrainingCourseList>
                  {training.particulier.map((trainingCourse) => {
                    const Icon = trainingCourseIcon[trainingCourse.icon]

                    return (
                      <li key={trainingCourse.url}>
                        <Link
                          href={`${trainingCourse.url}?utm_source=ulule&utm_medium=homepage&utm_campaign=featured_training_course`}
                        >
                          <TrainingCourse type={trainingCourse.type}>
                            <Icon size={28} />
                            <plume.styles.copy.S>{trainingCourse.name}</plume.styles.copy.S>
                            <plume.glyphs.stroke.ArrowRight size={17} />
                          </TrainingCourse>
                        </Link>
                      </li>
                    )
                  })}
                </TrainingCourseList>
              </>
            )}

            {training.organisation.length > 0 && (
              <>
                <plume.styles.heading.XXXS
                  as="a"
                  asDesktop="XS"
                  href="https://partenaires.ulule.com/formations-entreprise?utm_source=ulule&utm_medium=homepage&utm_campaign=training_courses_for_organizations"
                >
                  {t('For organizations')}
                </plume.styles.heading.XXXS>
                <TrainingCourseList>
                  {training.organisation.map((trainingCourse) => {
                    const Icon = trainingCourseIcon[trainingCourse.icon]
                    return (
                      <li key={trainingCourse.url}>
                        <Link
                          href={`${trainingCourse.url}?utm_source=ulule&utm_medium=homepage&utm_campaign=featured_training_course`}
                        >
                          <TrainingCourse type={trainingCourse.type}>
                            <Icon size={28} />
                            <plume.styles.copy.S>{trainingCourse.name}</plume.styles.copy.S>
                            <plume.glyphs.stroke.ArrowRight size={17} />
                          </TrainingCourse>
                        </Link>
                      </li>
                    )
                  })}
                </TrainingCourseList>
              </>
            )}
          </Wrapper>
        </style.ContrastedSection>
      </WideSection>
    </>
  )
}

const trainingCourseIcon = {
  bloc: plume.illustrations.training.Note,
  bulb: plume.illustrations.training.Bulb,
  certificate: plume.illustrations.training.Certificate,
  co2: plume.illustrations.training.Co2,
  creationsite: plume.illustrations.training.CreationSite,
  devices: plume.illustrations.training.Devices,
  earth: plume.illustrations.training.Earth,
  instagram: plume.illustrations.training.Instagram,
  linkedin: plume.illustrations.training.LinkedIn,
  megaphone: plume.illustrations.training.Megaphone,
  money: plume.illustrations.training.Money,
  seo: plume.illustrations.training.Seo,
  tiktok: plume.illustrations.training.Tiktok,
} satisfies Record<entities.homepage.TrainingCourse['icon'], any>

const Link = styled.a`
  text-decoration: none;
`

const VideoLoaderWrapper = styled.div`
  width: 220px;
  margin: 32px auto 24px;

  ${plume.VideoLoader} {
    width: 220px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    margin: 0;

    ${plume.VideoLoader}, ${plume.Picture} {
      width: 160px;
    }

    ${plume.Picture} {
      margin: 0 auto;
    }
  }
`

const Quote = styled.blockquote`
  height: max-content;
  margin: 0 16px;
  position: relative;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin: auto;
    max-width: 45%;
    top: -40px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    margin: 0 0 0 0;
    max-width: 45%;
    padding-right: 4px;
    top: 38px;
  }

  @media screen and (min-width: 1920px) {
    margin: 0 0 0 8px;
  }
`

const QuoteContent = styled(plume.styles.copy.M)`
  border-left: 4px solid ${plume.COLORS.BRAND_YELLOW};
  height: max-content;
  margin: 16px 0;
  padding-left: 16px;
`

const QuoteSource = styled(plume.styles.copy.M)`
  font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.M.semiBold, so this is just by anticipation.

  ${plume.styles.wysiwyg.Hyperlink} {
    color: ${plume.COLORS
      .PRIMARY_GREY_900}; // HACK: Irregular font manipulation. 'Natively', wysiwyg.Hyperlink is PRIMARY_BLUE, but here we use PRIMARY_GREY_900
  }
`

const TestimonialAside = styled.aside`
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    flex-direction: row;
    justify-content: center;
    margin: 56px auto 0;
    max-width: 760px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    justify-content: flex-end;
    left: 50%;
    margin: 0;
    position: absolute;
    top: -50px;
  }
`

const TrainingCourse = styled.div<{ type: string }>`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_GREY_000};
  border-radius: 40px;
  border: 1px solid ${plume.COLORS.BORDER_PRIMARY};
  display: flex;
  gap: 12px;
  height: 52px; // QUESTION: Isn't 64 better for small touch devices?
  padding: 14px 18px;
  width: max-content;
  margin: 16px 0 0 0;

  ${({ type }) => {
    if (type === 'organisation') {
      return css`
        ${plume.illustrations.Illustration} {
          color: ${plume.COLORS.PRIMARY_BLUE_200};
        }
      `
    }
    if (type === 'particulier') {
      return css`
        ${plume.illustrations.Illustration} {
          color: ${plume.COLORS.BRAND_YELLOW};
        }
      `
    }
  }}
  &:hover {
    border-color: ${plume.COLORS.PRIMARY_SAND_700};

    ${plume.glyphs.stroke.ArrowRight}, ${plume.styles.copy.S} {
      color: ${plume.COLORS.PRIMARY_BLUE_700};
    }
  }

  ${plume.glyphs.stroke.ArrowRight} {
    color: ${plume.COLORS.PRIMARY_GREY_500};
  }

  ${plume.styles.copy.S} {
    font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.S.semiBold, so this is just by anticipation.
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    height: 64px;
    padding: 20px 28px;
    margin: 16px 16px 0 0;
  }
`

const TrainingCourseList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 10px;
  list-style: none;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const WideSection = styled(style.WideSection)`
  display: flex;
  flex-direction: column;
  overflow: initial;

  ${style.ContrastedSection} {
    order: 1;
  }

  ${TestimonialAside} {
    order: 2;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    display: block;
  }
`

/**
 * VECTORS
 */

const QuoteSymbol = styled((props: React.SVGAttributes<SVGSymbolElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" {...props}>
      <circle cx="20" cy="20" r="20" fill={plume.COLORS.BACKGROUND_ALERT} />
      <path
        fill={plume.COLORS.BRAND_YELLOW}
        d="M14.951 18.364a4.02 4.02 0 0 1 2.186.643 3.845 3.845 0 0 1 1.449 1.714c.297.697.375 1.465.224 2.206a3.784 3.784 0 0 1-1.077 1.955 3.971 3.971 0 0 1-2.014 1.045 4.044 4.044 0 0 1-2.273-.218 3.911 3.911 0 0 1-1.765-1.406 3.74 3.74 0 0 1-.663-2.121L11 21.636c0-2.025.829-3.967 2.304-5.4A7.99 7.99 0 0 1 18.867 14v2.182a5.732 5.732 0 0 0-2.152.412 5.6 5.6 0 0 0-2.38 1.816c.2-.031.406-.047.615-.047h.002Zm10.115 0a4.02 4.02 0 0 1 2.186.643 3.845 3.845 0 0 1 1.449 1.714c.297.697.375 1.465.223 2.206a3.785 3.785 0 0 1-1.076 1.955 3.971 3.971 0 0 1-2.014 1.045 4.044 4.044 0 0 1-2.273-.218 3.911 3.911 0 0 1-1.765-1.406 3.74 3.74 0 0 1-.663-2.121l-.018-.546c0-2.025.829-3.967 2.304-5.4A7.99 7.99 0 0 1 28.982 14v2.182a5.732 5.732 0 0 0-2.152.412 5.6 5.6 0 0 0-2.38 1.816c.2-.031.406-.047.616-.047Z"
      />
    </svg>
  )
})`
  margin-left: -10px;
`

const Wrapper = styled.div`
  max-width: 1330px;
  margin: 0 auto;

  ${plume.styles.heading.XXXS} {
    display: inline-block;
    margin-bottom: 4px;
    text-decoration: underline;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 24px;
    }

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      margin: 0 0 4px;
    }
  }
`

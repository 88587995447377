import * as React from 'react'

type ScrollData = { x: number; y: number; dx: number; dy: number }

export function useScrollListener(target: React.RefObject<HTMLElement>, onScroll: (scroll: ScrollData) => void): void {
  const scrollRef = React.useRef<ScrollData>({ x: 0, y: 0, dx: 0, dy: 0 })

  React.useEffect(() => {
    if (target.current === null) {
      return
    }

    handleScroll()
    target.current.addEventListener('scroll', handleScroll)
    return () => {
      if (target.current === null) {
        return
      }

      target.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function handleScroll(): void {
    if (target.current === null) {
      return
    }

    const x = target.current.scrollLeft
    const y = target.current.scrollTop

    scrollRef.current = { x, y, dx: x - scrollRef.current.x, dy: y - scrollRef.current.y }
    onScroll(scrollRef.current)
  }
}

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'zero-waste-boutique-illustration-icon'

export function ZeroWasteSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="m64.931 31.72-33.306-3.757.399-3.536a3 3 0 0 1 3.317-2.644l27.344 3.084a3 3 0 0 1 2.645 3.317l-.399 3.536z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m33.797 28.207 28.962 3.267-4.45 28.37a2 2 0 0 1-2.2 1.677l-22.523-2.54a2 2 0 0 1-1.77-2.126l1.981-28.647z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
        />
        <path
          d="m41.773 22.508.51-4.529a2 2 0 0 1 2.212-1.763L55.001 17.4a2 2 0 0 1 1.764 2.212l-.511 4.529"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59 30.87H21v-4.435a3 3 0 0 1 3-3h32a3 3 0 0 1 3 3v4.434z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.478 30.87h33.044l-1.42 32.782a2 2 0 0 1-1.998 1.913H26.895a2 2 0 0 1-1.998-1.913L23.478 30.87z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
        />
        <path
          d="M29.261 47.162c0 5.879 2.073 8.805 6.22 8.805 4.838 0 6.144-3.92 6.144-8.805 0-2.613-.333-4.573-1.229-6.245-.896-1.672-2.56-2.613-4.915-2.613-4.147 0-6.22 2.953-6.22 8.858zm3.149 0c0-3.815 1.024-5.722 3.071-5.722 2.023 0 3.02 1.907 3.02 5.722 0 3.788-.997 5.67-3.02 5.67-2.047 0-3.071-1.882-3.071-5.67z"
          fill={colors.PRIMARY_BLACK}
        />
        <circle cx="44.309" cy="40.989" r="1.325" transform="rotate(29.056 44.31 40.989)" fill={colors.PRIMARY_BLACK} />
        <path
          d="m49.686 41.385-5.3 5.299"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="49.764" cy="47.079" r="1.325" transform="rotate(29.056 49.764 47.08)" fill={colors.PRIMARY_BLACK} />
        <path
          d="M31.74 23.435V18a2 2 0 0 1 2-2h12.52a2 2 0 0 1 2 2v5.435"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const ZeroWasteStandaloneContent = standalone(ZeroWasteSymbol, ID)

export const ZeroWaste = styled(function ZeroWasteStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="zero-waste">
      <ZeroWasteStandaloneContent />
    </illustration.Illustration>
  )
})``

export const ZeroWasteLightweight = styled(function ZeroWasteComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="zero-waste">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

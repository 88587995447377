import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../styles'
import { StatusPastilleColor } from '../../styles/tag'

export type StatusPillProps = {
  color?: StatusPastilleColor
  bordered?: boolean
} & React.HTMLAttributes<HTMLDivElement>

function StatusPillComponent({
  children,
  color = 'grey',
  ...props
}: StatusPillProps): React.ReactElement<StatusPillProps> {
  return (
    <S.tag.StatusPill {...props}>
      <S.tag.StatusPastille color={color} />
      <S.copy.XS>{children}</S.copy.XS>
    </S.tag.StatusPill>
  )
}

export const StatusPill = styled(StatusPillComponent)<StatusPillProps>``
StatusPill.displayName = 'StatusPill'

import * as React from 'react'
import {
  documentToReactComponents,
  type RenderMark,
  type RenderNode,
  type Options,
} from '@contentful/rich-text-react-renderer'
import * as richtext from '@contentful/rich-text-types'

import * as contentful from '@owl-nest/contentful'

export type RichTextProps<ENTRY extends { sys: { id: string } }> = {
  document: richtext.Document
  links?: {
    assets?: {
      block: contentful.Asset[]
    }
    entries?: {
      block: ENTRY[]
    }
  }
  renderEntry?: (entry: ENTRY) => React.ReactNode
  renderMark?: {
    Bold?: React.ComponentType<{ children: React.ReactNode }>
    Italic?: React.ComponentType<{ children: React.ReactNode }>
    Underline?: React.ComponentType<{ children: React.ReactNode }>
  }
  renderNode?: {
    Paragraph?: React.ComponentType<{ children: React.ReactNode }>
    Title1?: React.ComponentType<{ children: React.ReactNode }>
    Title2?: React.ComponentType<{ children: React.ReactNode }>
    Title3?: React.ComponentType<{ children: React.ReactNode }>
    Title4?: React.ComponentType<{ children: React.ReactNode }>
    Title5?: React.ComponentType<{ children: React.ReactNode }>
    Title6?: React.ComponentType<{ children: React.ReactNode }>
    UnorderedList?: React.ComponentType<{ children: React.ReactNode }>
    OrderedList?: React.ComponentType<{ children: React.ReactNode }>
    ListItem?: React.ComponentType<{ children: React.ReactNode }>
    BlockQuote?: React.ComponentType<{ children: React.ReactNode }>
    Table?: React.ComponentType<{ children: React.ReactNode }>
    TableCell?: React.ComponentType<{ children: React.ReactNode }>
    TableHeaderCell?: React.ComponentType<{ children: React.ReactNode }>
    TableRow?: React.ComponentType<{ children: React.ReactNode }>
    Hyperlink?: (props: { uri: string; children: React.ReactNode }) => React.ReactElement
    EmbeddedAsset?: (props: { asset: contentful.Asset }) => React.ReactElement
  }
}

export function RichText<ENTRY extends { sys: { id: string } }>({
  document,
  links,
  renderEntry,
  renderMark,
  renderNode,
}: RichTextProps<ENTRY>): React.ReactElement<RichTextProps<ENTRY>> {
  const blockAssetMap = new Map<string, contentful.Asset>()
  const blockEntryMap = new Map<string, ENTRY>()

  for (const asset of links?.assets?.block || []) {
    blockAssetMap.set(asset.sys.id, asset)
  }
  for (const entry of links?.entries?.block || []) {
    blockEntryMap.set(entry.sys.id, entry)
  }

  const richTextOptions = React.useMemo<Options>(
    () => ({
      renderMark: {
        [richtext.MARKS.BOLD]: makeRenderMark(renderMark?.Bold),
        [richtext.MARKS.ITALIC]: makeRenderMark(renderMark?.Italic),
        [richtext.MARKS.UNDERLINE]: makeRenderMark(renderMark?.Underline),
      },
      renderNode: {
        [richtext.BLOCKS.PARAGRAPH]: makeRenderNode(renderNode?.Paragraph),
        [richtext.BLOCKS.HEADING_1]: makeRenderNode(renderNode?.Title1),
        [richtext.BLOCKS.HEADING_2]: makeRenderNode(renderNode?.Title2),
        [richtext.BLOCKS.HEADING_3]: makeRenderNode(renderNode?.Title3),
        [richtext.BLOCKS.HEADING_4]: makeRenderNode(renderNode?.Title4),
        [richtext.BLOCKS.HEADING_5]: makeRenderNode(renderNode?.Title5),
        [richtext.BLOCKS.HEADING_6]: makeRenderNode(renderNode?.Title6),
        [richtext.BLOCKS.UL_LIST]: makeRenderNode(renderNode?.UnorderedList),
        [richtext.BLOCKS.OL_LIST]: makeRenderNode(renderNode?.OrderedList),
        [richtext.BLOCKS.LIST_ITEM]: makeRenderNode(renderNode?.ListItem),
        [richtext.BLOCKS.QUOTE]: makeRenderNode(renderNode?.BlockQuote),
        [richtext.BLOCKS.TABLE]: makeRenderNode(renderNode?.Table),
        [richtext.BLOCKS.TABLE_CELL]: makeRenderNode(renderNode?.TableCell),
        [richtext.BLOCKS.TABLE_HEADER_CELL]: makeRenderNode(renderNode?.TableHeaderCell),
        [richtext.BLOCKS.TABLE_ROW]: makeRenderNode(renderNode?.TableRow),
        [richtext.INLINES.HYPERLINK]: (node, children) => {
          const Hyperlink = renderNode?.Hyperlink
          if (!Hyperlink) {
            return <></>
          }
          return <Hyperlink uri={node.data.uri}>{children}</Hyperlink>
        },
        [richtext.BLOCKS.EMBEDDED_ASSET]: (node) => {
          const asset = blockAssetMap.get(node.data.target.sys.id)

          const EmbeddedAsset = renderNode?.EmbeddedAsset

          if (asset === undefined || EmbeddedAsset === undefined) {
            return <></>
          }

          if (EmbeddedAsset) {
            return <EmbeddedAsset asset={asset} />
          }
        },
        [richtext.BLOCKS.EMBEDDED_ENTRY]: (node) => {
          const entry = blockEntryMap.get(node.data.target.sys.id)
          if (entry === undefined || renderEntry === undefined) {
            return <></>
          }
          return renderEntry(entry)
        },
      },
    }),
    [],
  )

  return <>{documentToReactComponents(document, richTextOptions)}</>
}

function makeRenderMark(Component?: React.ComponentType<{ children: React.ReactNode }>): RenderMark[string] {
  // eslint-disable-next-line react/display-name
  return (text) => {
    if (!Component) {
      return <></>
    }
    return <Component>{text}</Component>
  }
}

function makeRenderNode(Component?: React.ComponentType<{ children: React.ReactNode }>): RenderNode[string] {
  // eslint-disable-next-line react/display-name
  return (node, children) => {
    if (!Component) {
      return <></>
    }
    return <Component>{children}</Component>
  }
}

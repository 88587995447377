const formatterCache: Record<string, Intl.NumberFormat> = {}

type FormatterConfig = {
  currency?: string
  locale?: string
}

const DEFAULT_FORMATTER_CONFIG: FormatterConfig = {
  currency: 'EUR',
  locale: 'fr-FR',
}

export function format(
  amount: number,
  { currency = DEFAULT_FORMATTER_CONFIG.currency, locale = DEFAULT_FORMATTER_CONFIG.locale }: FormatterConfig = {},
): string {
  return getFormatter({ locale, currency })
    .format(amount)
    .replace(/([.,])00/g, '')
}

function getFormatter(config: FormatterConfig): Intl.NumberFormat {
  const cacheKey = `${config.currency}-${config.locale}`

  if (formatterCache[cacheKey] === undefined) {
    formatterCache[cacheKey] = new Intl.NumberFormat(config.locale, {
      currency: config.currency,
      style: 'currency',
    })
  }
  return formatterCache[cacheKey]
}

type RangeFormatterConfig = FormatterConfig & { rangeString?: (minPriceString: string) => string }

export function formatRange(
  range: [number, number],
  {
    currency = DEFAULT_FORMATTER_CONFIG.currency,
    locale = DEFAULT_FORMATTER_CONFIG.locale,
    rangeString = (minPriceString: string) => {
      return `dès ${minPriceString}`
    },
  }: RangeFormatterConfig = {},
): string {
  return range[0] === range[1]
    ? `${format(range[0], { currency, locale })}`
    : rangeString(format(range[0], { currency, locale }))
}

type DiscountFormatterConfig = FormatterConfig & { freeProductString?: string }
export function formatDiscount(
  amount: number,
  {
    currency = DEFAULT_FORMATTER_CONFIG.currency,
    freeProductString = 'Gratuit',
    locale = DEFAULT_FORMATTER_CONFIG.locale,
  }: DiscountFormatterConfig = {},
): string {
  if (amount === 0) {
    return freeProductString
  }
  return format(amount, { currency, locale })
}

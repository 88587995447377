import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'seo-illustration-icon'

export function SeoSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 90 80">
      <g>
        <path
          d="M86.9 16.3c0-11.9-3.2-14.9-16.1-14.9H31c-12.8 0-16.1 3-16.1 14.9v21.2c0 11.9 3.2 14.9 16.1 14.9h32.6c11.1 0 16.7 0 20-3.3 3.5-3.3 3.5-8.6 3.5-19.1V16.3z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.9 70h24.3"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M43.4 53.1h15V69h-15z" fill="currentColor" />
        <path
          d="M52.9 62.4H20.2c-13.7 0-17.6-3.9-17.6-17.3V22.8C2.6 9.4 6.5 5.5 20.2 5.5h40.2C74.1 5.5 78 9.4 78 22.8v14.6c0 11.6 0 17.3-3.9 21.2-4 3.8-9.7 3.8-21.2 3.8zM20.2 8.5C8 8.5 5.6 10.9 5.6 22.8v22.4C5.6 57 8 59.4 20.2 59.4h32.7c10.7 0 16.1 0 19.1-3s3-8.4 3-19.1V22.8c0-11.9-2.4-14.3-14.6-14.3H20.2z"
          fill={colors.PRIMARY_GREY_900}
        />
        <path
          d="M47.2 79.9c-.9 0-1.5-.6-1.5-1.5V61.2c0-.9.6-1.5 1.5-1.5s1.5.6 1.5 1.5v17.3c0 .8-.8 1.4-1.5 1.4zM33.7 79.9c-.9 0-1.5-.6-1.5-1.5V61.2c0-.9.6-1.5 1.5-1.5s1.5.6 1.5 1.5v17.3c0 .8-.8 1.4-1.5 1.4z"
          fill={colors.PRIMARY_GREY_900}
        />
        <path
          d="M52.4 79.9H28.1c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h24.3c.9 0 1.5.6 1.5 1.5s-.7 1.5-1.5 1.5zM55.9 22.6l-5.9.6c-1.1.2-1.8 1.1-1.7 2.1.2 1.1 1.1 1.8 2.1 1.7h.8l-8.1 9.5-5.9-4.7c-.6-.5-1.7-.5-2.3.3L23.5 45.7c-.6.8-.6 2 .2 2.7.8.6 2 .6 2.7-.2L36.6 36l5.9 4.7c.6.5 1.7.5 2.3-.2l9.3-10.8c0 1.1.9 1.8 1.8 1.8 1.1 0 1.8-.9 1.8-1.8v-5.4c-.2-1.1-.9-1.8-1.8-1.7z"
          fill={colors.PRIMARY_GREY_900}
        />
      </g>
    </symbol>
  )
}

const SeoStandaloneContent = standalone(SeoSymbol, ID)

export const Seo = styled(function SeoStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="seo">
      <SeoStandaloneContent />
    </illustration.Illustration>
  )
})`
  fill: none;
`

export const SeoLightweight = styled(function SeoComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="seo">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})`
  fill: none;
`

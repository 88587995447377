import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

export const Pop = styled((props: React.SVGAttributes<SVGSymbolElement>): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 30" fill="none" {...props}>
      <path
        fill={props.color || plume.COLORS.PRIMARY_BLUE}
        d="M20.221 16.923A3.35 3.35 0 0 0 22.701 18a3.52 3.52 0 0 0 1.442-.313c1.367-.61 2.084-1.951 1.793-3.334L23.44 2.47c-.194-.938-.837-1.723-1.756-2.147a3.49 3.49 0 0 0-2.898-.014l-5.939 2.688c-.918.417-1.568 1.188-1.778 2.119-.209.93.053 1.882.718 2.605l8.433 9.203ZM3.189 29.991l11.95-.892c.488-.04.944-.18 1.339-.41a3.068 3.068 0 0 0 1.458-2.047c.28-1.368-.369-2.684-1.625-3.275L5.554 18.288a3.002 3.002 0 0 0-2.67.052 3.057 3.057 0 0 0-1.626 2.144l-1.2 5.976a2.96 2.96 0 0 0 .677 2.552c.62.693 1.51 1.049 2.454.98Z"
      />
    </svg>
  )
})``

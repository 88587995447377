import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../../icons/glyphs'
import * as S from '../../styles'
import { Button } from '../button/Button'
import { Link } from '../Link'
import { Picture } from '../Picture'
import { RoundButton } from '../RoundButton'
import { StatusPill } from '../tag/StatusPill'
import { Tag } from '../tag/Tag'

export type PartnerOperationCardProps = {
  buttonUrl: string
  buttonTarget?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
  className?: string
  compact?: boolean
  isOngoing: boolean
  linkUrl?: string
  linkTarget?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
  pictureUrl: string
  pictureSrcSet: string
  translation: {
    description: React.ReactNode
    keyList?: React.ReactNode
    keyTitle?: string
    buttonMore: string
    link: string
    status: string
    tag?: string
  }
}

function PartnerOperationCardComponent({
  buttonUrl,
  buttonTarget,
  className,
  compact = false,
  isOngoing,
  linkUrl,
  linkTarget,
  pictureUrl,
  pictureSrcSet,
  translation,
}: PartnerOperationCardProps): React.ReactElement<PartnerOperationCardProps> {
  return (
    <S.card.partner.OperationWrapper className={className}>
      <S.card.partner.OperationHeader>
        <StatusPill color={isOngoing ? 'green' : 'grey'}>{translation.status}</StatusPill>
        <S.card.partner.OperationLink target="_blank" rel="noreferrer" href={buttonUrl}>
          <OperationPicture alt="Operation partner image" loading="lazy" src={pictureUrl} srcSet={pictureSrcSet} />
        </S.card.partner.OperationLink>
      </S.card.partner.OperationHeader>
      {compact ? (
        <CompactOperationContent
          buttonUrl={buttonUrl}
          buttonTarget={buttonTarget}
          linkUrl={linkUrl}
          linkTarget={linkTarget}
          translation={translation}
        />
      ) : (
        <OperationContent buttonUrl={buttonUrl} linkUrl={linkUrl} translation={translation} />
      )}
    </S.card.partner.OperationWrapper>
  )
}

const OperationPicture = styled(Picture)`
  width: 100%;
`

type OperationContent = {
  buttonUrl: string
  linkUrl?: string
  translation: {
    description: React.ReactNode
    keyList?: React.ReactNode
    keyTitle?: string
    buttonMore: string
    link: string
    tag?: string
  }
}

function OperationContent({ buttonUrl, linkUrl, translation }: OperationContent): React.ReactElement {
  return (
    <S.card.partner.OperationContent>
      <Tag color="sand">{translation.tag}</Tag>
      <S.card.partner.OperationDescription>{translation.description}</S.card.partner.OperationDescription>
      <S.card.partner.KeyList>
        <S.copy.M>{translation.keyTitle}</S.copy.M>
        {translation.keyList}
      </S.card.partner.KeyList>
      <Button forwardedAs="a" href={buttonUrl}>
        {translation.buttonMore}
      </Button>
      {linkUrl && (
        <S.card.partner.LinkWrapper>
          <Link kind={'secondary'} href={linkUrl}>
            {translation.link}
          </Link>
        </S.card.partner.LinkWrapper>
      )}
    </S.card.partner.OperationContent>
  )
}

type CompactOperationContent = {
  buttonUrl: string
  buttonTarget?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
  linkUrl?: string
  linkTarget?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
  translation: {
    description: React.ReactNode
    buttonMore: string
    link: string
  }
}

function CompactOperationContent({
  buttonUrl,
  buttonTarget,
  translation,
  linkUrl,
  linkTarget,
}: CompactOperationContent): React.ReactElement {
  return (
    <S.card.partner.OperationContent compact>
      <S.card.partner.CompactOperationDescription>{translation.description}</S.card.partner.CompactOperationDescription>
      <RoundButton
        forwardedAs="a"
        href={buttonUrl}
        target={buttonTarget}
        contents={{
          active: (
            <>
              <span>{translation.buttonMore}</span>
              <glyphs.stroke.ArrowRight />
            </>
          ),
          inactive: (
            <>
              <span>{translation.buttonMore}</span>
              <glyphs.stroke.ArrowRight />
            </>
          ),
        }}
      />
      {linkUrl && (
        <S.card.partner.LinkWrapper>
          <Link kind={'primary'} href={linkUrl} target={linkTarget} darkened tinted>
            {translation.link}
          </Link>
        </S.card.partner.LinkWrapper>
      )}
    </S.card.partner.OperationContent>
  )
}

export const PartnerOperationCard = styled(PartnerOperationCardComponent)<PartnerOperationCardProps>``

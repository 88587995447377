import * as React from 'react'
import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as S from '../styles'
import * as utils from '../utils'

import { CriterionList, type Criteria } from './CriterionList'
import { Picture } from './Picture'

export type ProductCardProps = {
  brand: string
  className?: string
  criteria?: Criteria
  cta?: React.ReactNode
  originalPrice?: string
  picture: {
    alt?: string | null
    src?: string | null
    srcSet?: string | null
    nativeLazyLoad?: boolean
  }
  price: string
  title: string
  variantInformation?: string
}

function ProductCardComponent({
  brand,
  className,
  criteria,
  cta,
  originalPrice,
  picture,
  price,
  title,
  variantInformation,
}: ProductCardProps): React.ReactElement {
  const [isClientSide, setIsClientSide] = React.useState(false)
  React.useEffect(() => {
    setIsClientSide(true)
  }, [])

  return (
    <S.card.product.Wrapper className={className}>
      <StyledPicture
        nativeLazyLoad={picture.nativeLazyLoad}
        src={picture.src ?? ''}
        srcSet={picture.srcSet ?? ''}
        alt={picture.alt ?? undefined}
      />
      <S.card.product.Informations>
        {criteria && <CriterionList maxToShow={isClientSide && utils.isMobile() ? 4 : 5} criteria={criteria} />}
        <S.heading.XXXS asDesktop="XS">{title}</S.heading.XXXS>
        <S.copy.XS asDesktop="M">{brand}</S.copy.XS>
        <S.card.product.BottomInformation withCta={Boolean(cta)}>
          <S.card.product.VariantInformation asDesktop="S">
            {variantInformation ?? ''}
          </S.card.product.VariantInformation>

          <S.card.product.Prices>
            {originalPrice && (
              <S.card.product.DiscountPrice asDesktop="S">{originalPrice}</S.card.product.DiscountPrice>
            )}
            <S.card.product.Price asDesktop="L">{price}</S.card.product.Price>
          </S.card.product.Prices>
        </S.card.product.BottomInformation>
        {cta}
      </S.card.product.Informations>
    </S.card.product.Wrapper>
  )
}

export const StyledPicture = styled(Picture)`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  ${S.picture.Image} {
    transition: transform 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and ${breakpoint.LAPTOP} {
    ${S.card.product.Wrapper}:hover & {
      ${S.picture.Image} {
        transform: scale(1.1);
      }
    }
  }

  @media screen and ${breakpoint.TABLET} {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }
`

export const ProductCard = styled(ProductCardComponent)``

type ProductCardSkeletonProps = {
  className?: string
}

function ProductCardSkeletonComponent({ className }: ProductCardSkeletonProps) {
  return (
    <S.card.product.Wrapper className={className}>
      <StyledPictureSkeleton as={'div'} />
      <S.card.product.Informations>
        <S.card.criterion.Criteria>
          <S.skeleton.Circle radius={20} />
          <S.skeleton.Circle radius={20} />
          <S.skeleton.Circle radius={20} />
        </S.card.criterion.Criteria>
        <S.skeleton.Text />
        <S.skeleton.Text />
        <S.skeleton.Text size="small" width={60} />
        <S.card.product.BottomInformation>
          <S.card.product.Prices>
            <S.skeleton.Text size="small" width={50} />
          </S.card.product.Prices>
        </S.card.product.BottomInformation>
      </S.card.product.Informations>
    </S.card.product.Wrapper>
  )
}

export const ProductCardSkeleton = styled(ProductCardSkeletonComponent)`
  ${S.card.criterion.Criteria} {
    ${S.skeleton.Circle} {
      margin-bottom: 7px;
      margin-right: 5px;
    }
  }
`

export const StyledPictureSkeleton = styled(StyledPicture)`
  ${S.skeleton.skeletonAnimationRules}
`

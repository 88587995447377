import type * as next from 'next'

import * as services from '@ulule/services'

import type { AppProps } from '@ulule/homepage'
export { App as default } from '@ulule/homepage'

import { LANGS } from '../../../constants/langs'

export async function getStaticProps(
  context: next.GetStaticPropsContext<Path['params']>,
): Promise<next.GetStaticPropsResult<AppProps>> {
  const locale = context.locale ?? context.defaultLocale

  if (context.params?.country === undefined || locale === undefined) {
    return {
      notFound: true,
    }
  }

  // HACK: There is a conflict between the management of i18n and Next's dynamic routes,
  // where the 'country' parameter('FR', 'GB'...) overrides Next's internal locale.
  if (context.params.country.length !== 4) {
    return { notFound: true }
  }
  // To work around this conflict, we add a 'c_' prefix to our 'country' parameters ('c_FR'...),
  // which needs to be removed here in order to correctly make requests to our various services.
  const country = context.params.country.slice(2)

  const homepage = await services.homepage.get({ country, locale })

  return {
    props: {
      homepage,
    },
    revalidate: 60,
  }
}

type Path = {
  locale: (typeof LANGS)[number]
  params: { country: string }
}

export async function getStaticPaths(): Promise<next.GetStaticPathsResult> {
  const paths: Path[] = [{ params: { country: 'c_FR' }, locale: 'fr' }]

  return {
    paths,
    fallback: 'blocking',
  }
}

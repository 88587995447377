import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'megaphone-illustration-icon'

export function MegaphoneSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 29 26">
      <g clipPath="url(#clip0_631_36946)">
        <path
          d="M11.232 15.4473L11.5592 21.6048C11.5878 22.8728 10.5687 23.9167 9.30143 23.9167C8.03417 23.9167 7.0129 22.8728 7.04365 21.6048L7.38627 15.144"
          fill="currentColor"
        />
        <path
          d="M11.232 15.4473L11.5592 21.6048C11.5878 22.8728 10.5687 23.9167 9.30143 23.9167C8.03417 23.9167 7.0129 22.8728 7.04365 21.6048L7.38627 15.144"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M20.7295 6.50073C22.5151 6.79301 23.8768 8.34448 23.8768 10.2146C23.8768 12.0847 22.5019 13.6494 20.7031 13.9328"
          fill="currentColor"
        />
        <path
          d="M20.7295 6.50073C22.5151 6.79301 23.8768 8.34448 23.8768 10.2146C23.8768 12.0847 22.5019 13.6494 20.7031 13.9328"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M15.6023 3.38261C14.3833 4.18032 13.0897 4.822 11.5347 4.9143C9.7206 5.02198 7.85375 5.0022 6.1055 5.57356C4.24744 6.18229 2.98238 7.91176 2.91649 9.86758H2.89453L2.90551 10.2126L2.89453 10.5576H2.91649C2.98238 12.5134 4.24525 14.2429 6.1055 14.8516C8.13707 15.5175 10.3048 15.3768 12.4045 15.5724C15.4815 15.8603 17.1968 18.4248 19.7225 19.7609C20.1617 19.9939 20.6911 19.673 20.6911 19.1764V10.5576V9.86977V1.2356C20.6911 0.741153 20.1705 0.426904 19.7291 0.648856C18.2598 1.38504 16.9749 2.48381 15.6001 3.38261H15.6023Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M9.32572 16.9351L9.65296 22.8729C9.68152 24.1409 8.66244 25.1847 7.39518 25.1847C6.12792 25.1847 5.10665 24.1409 5.1374 22.8729L5.48002 16.6318"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M18.8193 7.7688C20.6049 8.06107 21.9666 9.61254 21.9666 11.4827C21.9666 13.3528 20.5917 14.9174 18.793 15.2009"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M13.6977 4.65067C12.4788 5.44839 11.1852 6.09007 9.63019 6.18237C7.81605 6.29005 5.94921 6.27027 4.20096 6.84163C2.3429 7.45255 1.08004 9.18202 1.01415 11.1378H0.992187L1.00317 11.4829L0.992187 11.8279H1.01415C1.08004 13.7837 2.3429 15.5132 4.20316 16.1219C6.23473 16.7877 8.40246 16.6471 10.5021 16.8427C13.5791 17.1306 15.2944 19.6951 17.8201 21.0312C18.2594 21.2641 18.7887 20.9433 18.7887 20.4467V11.8257V11.1378V2.50367C18.7887 2.00922 18.2682 1.69497 17.8267 1.91692C16.3574 2.6531 15.0726 3.75188 13.6977 4.65067Z"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24.3594 8.34907C25.2994 7.84144 26.2394 7.33381 27.1772 6.82617"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24.8281 11.461L28.2851 11.1621"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24.3633 14.5244C25.4153 14.9266 26.4673 15.3309 27.5193 15.7331"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_631_36946">
          <rect width="29" height="26" fill="white" />
        </clipPath>
      </defs>
    </symbol>
  )
}

const MegaphoneStandaloneContent = standalone(MegaphoneSymbol, ID)

export const Megaphone = styled(function MegaphoneStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="megaphone">
      <MegaphoneStandaloneContent />
    </illustration.Illustration>
  )
})`
  fill: none;
`

export const MegaphoneLightweight = styled(function MegaphoneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="megaphone">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})`
  fill: none;
`

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'earth-illustration-icon'

export function EarthSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 28">
      <g id="earth" clipPath="url(#clip0_1943_153399)">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.26815 9.22378C6.66321 2.64047 13.9429 -0.751915 20.5262 1.64315C26.5624 3.84145 29.916 10.1421 28.5856 16.2487C29.5517 16.1498 30.5296 16.1802 31.5023 16.2727C31.9003 16.3105 32.0646 16.8812 31.8281 17.2163C31.3662 17.8705 31.0677 18.6662 30.839 19.4516C30.7647 19.7067 30.6955 19.9644 30.6262 20.2223C30.3814 21.133 30.1362 22.0454 29.6679 22.8515L29.6684 22.8484C28.4731 24.8995 25.3329 26.3667 23.3774 24.5541C23.2633 24.4482 23.1593 24.337 23.0649 24.2211C19.8447 26.2954 15.7264 26.8926 11.8488 25.4819C5.26547 23.0843 1.87308 15.8071 4.26815 9.22378Z"
          fill="currentColor"
        />
        <g id="earth_2" fill="none">
          <g id="Clip path group">
            <mask
              id="mask0_1943_153399"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="28"
              height="28"
            >
              <g id="clippath">
                <path
                  id="Vector"
                  d="M18.4883 1.58382C11.678 -0.893831 4.14729 2.61553 1.66963 9.42585C-0.808016 16.2362 2.70135 23.7643 9.51167 26.2445C16.322 28.7222 23.8501 25.2128 26.3304 18.4025C28.808 11.5922 25.2987 4.06404 18.4883 1.58382Z"
                  fill="white"
                />
              </g>
            </mask>
            <g mask="url(#mask0_1943_153399)">
              <g id="Group">
                <path
                  id="Vector_2"
                  d="M0.895508 7.81212C1.5177 8.13043 2.65743 8.80967 2.97418 9.43775C3.32487 10.134 3.17498 10.5689 3.80848 11.089C4.524 11.6773 5.30173 12.1945 6.15866 12.5782C6.52914 12.743 6.97599 12.9789 6.95619 13.3683C6.93922 13.7064 6.57156 13.9139 6.38208 14.201C6.08795 14.6443 6.26613 15.2468 6.58853 15.6703C6.91377 16.0937 7.36627 16.4149 7.70565 16.827C8.98396 18.3929 8.2939 20.7632 9.25546 22.5224C9.41101 22.8037 9.66554 23.0964 9.99643 23.0822C10.3641 23.068 10.5988 22.6787 10.6271 22.3291C10.6554 21.9767 10.5507 21.6243 10.5903 21.2747C10.6469 20.766 10.9891 20.3369 11.3172 19.9333C12.0553 19.0352 12.7906 18.1343 13.5288 17.2362C13.8568 16.8355 14.1934 16.4206 14.3348 15.9289C14.4762 15.4372 14.3744 14.8461 13.9502 14.5363C13.4892 14.201 12.7991 14.2862 12.3296 13.9622C11.9082 13.6723 11.7725 13.1409 11.4897 12.726C10.5564 11.3561 8.39571 11.5721 6.88549 10.8048C6.20108 10.458 5.62414 9.55712 6.15866 9.01714C6.69035 8.48 7.6406 8.93756 8.39571 8.77841C9.54393 8.53399 9.79846 7.04194 10.7317 6.35986C11.2295 5.99608 11.88 5.89377 12.4258 5.60104C12.9716 5.30832 13.4156 4.64329 13.0989 4.12888C12.8019 3.65142 12.0695 3.65427 11.518 3.82479C10.9665 3.99531 10.4009 4.27951 9.8324 4.16014C9.34313 4.05499 8.95285 3.62869 8.91043 3.15123C8.81993 2.15937 10.1209 1.01847 10.1972 0.0266071C10.217 -0.217806 10.1661 -0.484954 9.98795 -0.661159C9.66554 -0.979464 9.11405 -0.871468 8.67852 -0.720841C7.45111 -0.291698 6.53763 0.171549 5.74292 1.12931C5.11225 1.88812 4.47875 3.51785 3.84808 4.27382C2.86671 5.45326 1.88535 6.63269 0.901164 7.81212H0.895508Z"
                  stroke={colors.PRIMARY_GREY_900}
                  strokeWidth="1.5"
                />
                <path
                  id="Vector_3"
                  d="M17.9153 0.004308C18.6142 0.792861 18.1311 2.76424 17.0452 3.12308C16.5668 3.28035 15.8773 3.41768 15.9125 3.84961C15.9289 4.03568 16.0931 4.18187 16.215 4.33692C16.7192 4.97264 16.5363 5.83872 16.1541 6.53202C15.7718 7.22533 15.2113 7.85661 14.9697 8.59644C14.7305 9.33626 14.9299 10.2666 15.7343 10.6188C16.3581 10.8934 17.0992 10.7561 17.7136 11.0573C18.3281 11.3586 18.7361 11.9699 18.9495 12.5458C19.1958 13.2126 19.2638 13.9192 19.496 14.5903C19.8196 15.5184 20.5888 15.5229 21.2033 14.7697C21.8037 14.0366 22.0382 13.1373 22.4627 12.3199C22.8848 11.5026 24.8801 7.4492 28.4375 9.72538L23.7901 3.93378L17.913 -0.00012207L17.9153 0.004308Z"
                  stroke={colors.PRIMARY_GREY_900}
                  strokeWidth="1.5"
                />
              </g>
            </g>
          </g>
          <path
            id="Vector_4"
            d="M27.1215 14.2187C27.2489 8.74642 23.9175 3.56106 18.4883 1.58382C11.678 -0.893831 4.14729 2.61553 1.66963 9.42585C-0.808016 16.2362 2.70135 23.7643 9.51167 26.2445C14.1399 27.9284 19.0998 26.847 22.5814 23.8437"
            stroke={colors.PRIMARY_GREY_900}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
        <g id="leafs">
          <path
            id="Vector_5"
            fill="none"
            d="M28.7906 20.7957C29.179 19.7159 29.179 18.5445 29.2887 17.4027C29.3984 16.2609 29.6504 15.069 30.4242 14.2192C28.8262 14.3314 27.2164 14.5438 25.7281 15.1309C24.2398 15.718 22.8671 16.7094 22.0755 18.0961C21.0764 19.8457 21.1001 22.4804 23.0183 23.6045C25.2507 24.9115 28.0168 22.9348 28.7906 20.7928V20.7957Z"
            stroke={colors.PRIMARY_GREY_900}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_6"
            d="M26.1924 18.1218C25.6963 20.0257 24.4826 21.9178 22.9328 23.4678"
            stroke={colors.PRIMARY_GREY_900}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1943_153399">
          <rect width="31.9375" height="28" fill="white" />
        </clipPath>
      </defs>
    </symbol>
  )
}

const EarthStandaloneContent = standalone(EarthSymbol, ID)

export const Earth = styled(function EarthStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="earth">
      <EarthStandaloneContent />
    </illustration.Illustration>
  )
})``

export const EarthLightweight = styled(function EarthComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="earth">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type ChannelCardProps = {
  avatar?: string
  button?: React.ReactNode
  description: string
  href: string
  src?: string
  title: string
}

function ChannelCardComponent({
  avatar,
  button,
  description,
  href,
  src,
  title,
}: ChannelCardProps): React.ReactElement<ChannelCardProps> {
  return (
    <S.channelCard.Wrapper href={href}>
      <S.channelCard.Img src={src} alt="" />
      <S.channelCard.Container>
        <S.channelCard.Info>
          <S.channelCard.Title as="span">{title}</S.channelCard.Title>
          <S.channelCard.Description as="p">{description}</S.channelCard.Description>
        </S.channelCard.Info>
        <S.channelCard.Avatar src={avatar} />
      </S.channelCard.Container>
      {button && (
        <S.channelCard.SubButton
          // prevents route transition from S.channelCard.Wrapper (<a>)
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          {button}
        </S.channelCard.SubButton>
      )}
    </S.channelCard.Wrapper>
  )
}

export const ChannelCard = styled(ChannelCardComponent)<ChannelCardProps>``
ChannelCard.displayName = 'ChannelCard'

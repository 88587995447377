import * as React from 'react'
import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as S from '../styles'
import * as illustration from '../icons/illustrations'
import { MediumIcon } from '../icons/illustrations/Icon'

import { Tooltip } from './Tooltip'

export type Criteria = {
  icon: string
  name: string
}[]

type CriterionListProps = {
  maxToShow: number
  criteria: Criteria
}

function CriterionListComponent({ maxToShow, criteria }: CriterionListProps): React.ReactElement {
  return (
    <S.card.criterion.Criteria>
      {criteria.reduce<JSX.Element[]>((criteriaAccumulator, criterion, index) => {
        if (index < maxToShow) {
          const Illustration = (illustration.boutique as any)[criterion.icon]
          criteriaAccumulator.push(
            <CriterionWithHover key={criterion.icon}>
              <Illustration size={16} />
              <Tooltip position="top" fitToContent open={false}>
                <S.copy.S>{criterion.name}</S.copy.S>
              </Tooltip>
            </CriterionWithHover>,
          )
        }
        if (index === maxToShow) {
          criteriaAccumulator.push(
            <S.card.criterion.CriteriaEllipsis key="rest">
              <S.heading.Card1>{`+${criteria.length - maxToShow}`}</S.heading.Card1>
            </S.card.criterion.CriteriaEllipsis>,
          )
        }

        return criteriaAccumulator
      }, [])}
    </S.card.criterion.Criteria>
  )
}

export const CriterionList = styled(CriterionListComponent)``

const CriterionWithHover = styled(S.card.criterion.Criterion)`
  position: relative;

  ${MediumIcon} {
    height: 120px;
    width: 120px;
  }

  @media screen and ${breakpoint.TABLET} {
    height: 26px;
    width: 26px;

    svg {
      height: 26px;
      width: 26px;
    }
  }

  &:hover {
    ${Tooltip} {
      display: flex;
    }
  }
`

import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

export const Main = styled.div`
  position: relative;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin: 0 auto;
  }
`

/**
 * BUTTON
 */

export const CenteredCTA = styled(plume.ButtonAsLink)`
  display: block;
  margin: 40px auto 0;
  text-align: center;
  width: fit-content;
`

/**
 * SECTION
 */

export const ContrastedSection = styled.section`
  background-color: ${plume.COLORS.PRIMARY_SAND};
  padding: 24px 32px;
  width: 100%;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    padding: 30px 56px;
  }
`

export const Section = styled.section`
  margin-top: 20px;
  padding: 37px 16px 16px;
  position: relative;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin: 43px auto 0;
    padding: 100px 30px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    padding: 100px 50px;
  }
`

export const SectionTitle = styled(plume.styles.heading.L).attrs({ asDesktop: 'XL' })``

export const SectionSubTitle = styled(plume.styles.heading.XXXS).attrs({ asDesktop: 'XXS' })`
  margin-top: 16px;
`

export const TitleGroup = styled.header`
  margin: 0 16px 36px;
  max-width: 1330px;
  width: 89%;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin: 0 auto 36px;
  }
`

export const WideSection = styled(Section)`
  :not(:first-of-type) {
    margin-top: 65px;
  }

  & + & {
    margin-top: 80px;
  }

  overflow: hidden;
  padding: 0;
  width: 100%;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin-top: 0;
    padding: 50px 0;

    :not(:first-of-type) {
      margin-top: 0;
    }

    & + & {
      margin-top: 0;
    }
  }
`

import * as hooks from '@owl-nest/hooks'

export function getCountry(router: hooks.NextRouter): string {
  return (router.query.country as string).slice(2)
}

export function getCurrencyCode(router: hooks.NextRouter): string | undefined {
  return typeof router.query.currency_code === 'string' ? router.query.currency_code : undefined
}

export function getCurrentLocale(
  router: hooks.NextRouter,
): hooks.NextRouter['locale'] | hooks.NextRouter['defaultLocale'] {
  return router.locale ?? router.defaultLocale
}

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'linkedin-illustration-icon'

export function LinkedInSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 90 80">
      <g>
        <path
          d="M80.6 69.8H19.2c-2.4 0-4.3-1.9-4.3-4.3V4.8c0-2.4 1.9-4.3 4.3-4.3h61.4c2.4 0 4.3 1.9 4.3 4.3v60.7c0 2.4-1.9 4.3-4.3 4.3z"
          fill="currentColor"
        />
        <path
          fill={colors.PRIMARY_GREY_900}
          d="M72.8 79.5H10.7c-2.9 0-5.3-2.4-5.3-5.3V12.7c0-2.9 2.4-5.3 5.3-5.3h62.2c2.9 0 5.3 2.4 5.3 5.3v61.5c-.1 2.9-2.5 5.3-5.4 5.3zM10.7 10.2c-1.4 0-2.5 1.1-2.5 2.5v61.5c0 1.4 1.1 2.5 2.5 2.5h62.2c1.4 0 2.5-1.1 2.5-2.5V12.7c0-1.4-1.1-2.5-2.5-2.5H10.7z"
        />
        <circle fill={colors.PRIMARY_GREY_900} cx="21.5" cy="23.4" r="6.3" />
        <path
          fill={colors.PRIMARY_GREY_900}
          d="M27.1 70.5H15.9c-.7 0-1.2-.5-1.2-1.2V34.2c0-.7.5-1.2 1.2-1.2h11.2c.7 0 1.2.5 1.2 1.2v35.1c0 .7-.5 1.2-1.2 1.2zm-8.4-2.7h5.7c.7 0 1.2-.5 1.2-1.2V36.9c0-.7-.5-1.2-1.2-1.2h-5.7c-.7 0-1.2.5-1.2 1.2v29.7c0 .6.5 1.2 1.2 1.2zM67.6 70.5H56.4c-.7 0-1.2-.5-1.2-1.2V51.6c0-3.9-.3-6.9-4.3-7.2-1.4 0-2.5.4-3.3 1.3-2 2.1-1.8 6.1-1.8 6.2v17.5c0 .7-.5 1.2-1.2 1.2H33.5c-.7 0-1.2-.5-1.2-1.2V34.2c0-.7.5-1.2 1.2-1.2h10.7c.7 0 1.2.5 1.2 1.2v.4c4.6-3.1 9.1-2.6 9.8-2.5 4.3.3 7.6 1.8 9.9 4.5 3.8 4.3 3.6 10.2 3.5 10.8l.1 21.8c0 .8-.5 1.3-1.1 1.3zm-8.5-2.7h5.7c.7 0 1.2-.5 1.2-1.2l-.1-19.2v-.1s.3-5.3-2.9-8.9c-1.9-2.1-4.6-3.3-8.1-3.5h-.1c-.2 0-5.3-.8-9.8 3.6l-.3.3c-.8.7-2 .2-2-.8v-1c0-.7-.5-1.2-1.2-1.2h-5.3c-.7 0-1.2.5-1.2 1.2v29.7c0 .7.5 1.2 1.2 1.2h5.7c.7 0 1.2-.5 1.2-1.2V51.9c0-.1-.3-5.1 2.5-8.1 1.3-1.4 3.1-2.2 5.3-2.2h.1c7 .5 6.9 6.9 6.9 10v15c0 .6.6 1.2 1.2 1.2z"
        />
        <path
          d="M272.4 20.3c-4.6 0-8.8-1.5-12.2-4-3.9-2.9-6.7-7.1-7.7-12-.2-1.2-.4-2.5-.4-3.7H239V55.2c0 5.2-3.4 9.5-8.2 11.1-1.4.4-2.9.7-4.4.6-2-.1-3.8-.7-5.4-1.6-3.4-2-5.7-5.7-5.8-9.8-.1-6.5 5.3-11.9 11.9-11.9 1.3 0 2.6.2 3.7.6V31.1c-1.2-.2-2.5-.3-3.8-.3-7.3 0-14 3-18.9 8.3-3.7 4-5.9 9.2-6.2 14.6-.4 7.1 2.2 13.9 7.3 18.8.8.7 1.5 1.4 2.4 2 4.4 3.3 9.8 5.1 15.4 5.1 1.3 0 2.5-.1 3.8-.3 5.3-.8 10.2-3.2 14-6.9 4.7-4.6 7.3-10.7 7.4-17.2l-.1-28.9c2.3 1.7 4.7 3.1 7.4 4.2 4.1 1.7 8.5 2.6 13 2.6v-9.4l-.1-3.4c.1 0 0 0 0 0z"
          fill={colors.PRIMARY_GREY_900}
        />
      </g>
    </symbol>
  )
}

const LinkedInStandaloneContent = standalone(LinkedInSymbol, ID)

export const LinkedIn = styled(function LinkedInStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="linkedin">
      <LinkedInStandaloneContent />
    </illustration.Illustration>
  )
})`
  fill: none;
`

export const LinkedInLightweight = styled(function LinkedInComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="linkedin">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})`
  fill: none;
`

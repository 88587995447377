import * as React from 'react'
import { NextSeo } from 'next-seo'

import { t } from '@owl-nest/localize'
import * as entities from '@ulule/entities'
import { SITE_URL, FACEBOOK_APP_ID, SITE_TWITTER_HANDLE } from '@ulule/config'

export function Seo({ seo }: { seo: entities.homepage.Homepage['seo'] }): React.ReactElement {
  const description =
    seo?.description ??
    t(
      'Ulule makes it easy to take action and develop creative, sustainable projects. Crowdfunding, certified training courses and a store of sustainable brands.',
    )
  const title = seo?.title ?? t('Ulule: fund a project, train, get started')

  return (
    <NextSeo
      facebook={{
        appId: FACEBOOK_APP_ID,
      }}
      twitter={{
        site: SITE_TWITTER_HANDLE,
        cardType: 'summary_large_image',
      }}
      title={title}
      description={description}
      additionalLinkTags={[
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '48x48',
          href: SITE_URL + '/assets/img/common/favicon-48.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: SITE_URL + '/assets/img/common/favicon-32.png',
        },
      ]}
      openGraph={{
        description,
        title,
        images: [
          {
            alt: title,
            url: `${process.env.ASSET_PREFIX}/assets/img/common/default-share-image.jpg`,
          },
        ],
        url: 'https://www.ulule.com',
      }}
    />
  )
}

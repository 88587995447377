import * as React from 'react'
import styled from 'styled-components'

import * as entities from '@ulule/entities'
import * as hooks from '@owl-nest/hooks'
import { t, tc } from '@owl-nest/localize'
import * as money from '@owl-nest/money'
import * as plume from '@ulule/owl-kit-components/next'

import { Carousel } from '../components/Carousel'

import * as style from '../style'
import { getCurrentLocale } from '../router'

type BoutiqueProps = { boutique: entities.homepage.Homepage['boutique'] }

export function Boutique({ boutique }: BoutiqueProps): React.ReactElement {
  const router = hooks.useNextRouter()

  if (boutique.length === 0) {
    return <></>
  }

  const userLanguage = getCurrentLocale(router)

  return (
    <style.WideSection>
      <style.TitleGroup>
        <style.SectionTitle>
          {tc('The [highlight: store] of committed brands', {
            highlight: <plume.HighlightedTitle color="green" />,
          })}
        </style.SectionTitle>
        <style.SectionSubTitle>
          {t('More than %(quantity)s sustainable products from committed brands, selected for you.', {
            quantity: (8000).toLocaleString(userLanguage),
          })}
        </style.SectionSubTitle>
      </style.TitleGroup>
      <Carousel>
        <CardsWrapper>
          {boutique.map((searchHitProduct) => {
            return <ProductCard key={searchHitProduct.objectID} product={searchHitProduct} />
          })}
        </CardsWrapper>
      </Carousel>
      <style.CenteredCTA href="https://boutique.ulule.com?utm_source=ulule&utm_medium=homepage&utm_campaign=featured_product_list">
        {t('See all products')}
      </style.CenteredCTA>
    </style.WideSection>
  )
}

function ProductCardWithALink({
  className,
  product,
}: {
  className?: string
  product: entities.homepage.Homepage['boutique'][number]
}): React.ReactElement {
  return (
    <a
      className={className}
      href={`https://boutique.ulule.com${product.href}?utm_source=ulule&utm_medium=homepage&utm_campaign=product_in_featured_collection`}
      key={product.objectID}
    >
      <plume.ProductCard
        brand={product.brand}
        criteria={product.criteria}
        originalPrice={product.compareAtPriceRange ? money.formatRange(product.compareAtPriceRange) : undefined}
        picture={product.picture}
        price={money.formatRange(product.priceRange)}
        title={product.title}
        variantInformation={(product.colors?.length ?? 0) > 1 ? `${product.colors?.length} couleurs` : undefined}
      />
    </a>
  )
}

const ProductCard = styled(ProductCardWithALink)`
  color: initial;
  text-decoration: none;
`

const CardsWrapper = styled.div`
  white-space: nowrap;

  & > ${ProductCard} {
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%; // QUESTION: Shouldn't this be part of the component altogether?
    margin-right: 9px;
    width: 165px;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      margin-right: 16px;
    }
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${ProductCard} {
      margin-right: 20px;
      width: 250px;

      ${plume.styles.card.product.Informations} {
        white-space: pre-wrap;
      }

      &:first-child {
        margin-left: 30px;
      }

      &:last-child {
        margin-right: 30px;
      }
    }
  }

  @media screen and ${plume.BREAKPOINTS.DESKTOP} {
    ${ProductCard} {
      &:first-child {
        margin-left: 75px;
      }

      &:last-child {
        margin-right: 75px;
      }
    }
  }
`

import * as React from 'react'
import styled from 'styled-components'

import { t } from '@owl-nest/localize'
import * as hooks from '@owl-nest/hooks'
import * as utils from '@owl-nest/utils'
import * as entities from '@ulule/entities'
import * as plume from '@ulule/owl-kit-components/next'

import * as style from '../style'

type ChannelsProps = {
  channels: entities.homepage.Channel[]
}

export function Channels({ channels }: ChannelsProps): React.ReactElement {
  const router = hooks.useNextRouter()

  if (channels.length === 0) {
    return <></>
  }

  return (
    <Section>
      <plume.styles.heading.M>{t('Thematic channels')}</plume.styles.heading.M>

      <List channels={channels} />

      <Button href={`https://${router.locale ?? router.defaultLocale}.ulule.com/channels`}>
        {t('See all channels')}
      </Button>
    </Section>
  )
}

function List({ channels }: { channels: entities.homepage.Channel[] }): React.ReactElement {
  const isClientSide = hooks.useClientSide()

  const randomIndexes = utils.array.shuffle(Array.from({ length: channels.length }, (_, i) => i)).slice(0, 3)
  const randomChannels = randomIndexes.map((index) => channels[index])
  const restChannels = channels.filter((_, index) => !randomIndexes.includes(index))

  const displayedChannels = isClientSide ? randomChannels : channels.slice(0, 3)
  const seoChannels = isClientSide ? restChannels : channels.slice(3)

  return (
    <>
      <ChannelList>
        {displayedChannels.map((channel) => {
          const description = channel.isPartner
            ? t('With the support of %(name)s', { name: channel.user.name })
            : t('Curated by %(name)s', { name: channel.user.name })

          return (
            <li key={channel.absolute_url}>
              <plume.ChannelCard
                title={channel.name}
                description={description}
                href={channel.absolute_url}
                avatar={channel.avatar ?? ''}
                src={channel.backgroundImage ?? ''}
              />
            </li>
          )
        })}
      </ChannelList>

      <SeoOnlyList>
        {seoChannels.map((channel) => (
          <li key={channel.absolute_url}>
            <SeoLink href={channel.absolute_url}>{channel.name}</SeoLink>
          </li>
        ))}
      </SeoOnlyList>
    </>
  )
}

const Button = styled(plume.ButtonAsLink)`
  margin: auto;
  display: flex;
  margin-top: 30px;
  width: fit-content;
`

const ChannelList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    justify-content: center;
    flex-direction: row;
  }
`

const Section = styled(style.Section)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1330px;
  padding: 0;
  width: 89%;

  ${plume.styles.heading.M} {
    margin-bottom: 20px;
  }
`

const SeoOnlyList = styled.ul`
  list-style: none;
  height: 0;
  width: 0;
`

const SeoLink = styled.a`
  height: 0;
  width: 0;
  opacity: 0;
  pointer-events: none;
`

import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../styles'
import * as glyphs from '../../icons/glyphs'

export type SliderButtonProps = {
  direction: 'right' | 'left'
  size?: 'medium' | 'small'
} & React.ButtonHTMLAttributes<HTMLButtonElement>

function SliderButtonComponent({ direction, size = 'medium', ...buttonProps }: SliderButtonProps): React.ReactElement {
  return (
    <S.button.SliderButton size={size} {...buttonProps}>
      {direction === 'left' ? (
        <glyphs.stroke.CaretLeft size={size === 'medium' ? 16 : 12} />
      ) : (
        <glyphs.stroke.CaretRight size={size === 'medium' ? 16 : 12} />
      )}
    </S.button.SliderButton>
  )
}

export const SliderButton = styled(SliderButtonComponent)``

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'creationsite-illustration-icon'

export function CreationSiteSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 90 80">
      <g>
        <path
          d="M70.1.3H30.6C17 .3 13.1 4 13.1 16.6v21c0 12.6 3.9 16.2 17.4 16.2h12.3v14.5h-4.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h24.1c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-4.8V53.8h5.1c11.4 0 17.1 0 20.8-3.7 3.9-3.7 3.9-9.2 3.9-20V16.6C87.6 4 83.6.3 70.1.3z"
          fill="currentColor"
        />
        <path
          d="M59.7 5.9H19.8C6.3 5.9 2.4 9.8 2.4 23v22.2c0 13.2 3.9 17.1 17.4 17.1h11.9v14.4h-4c-.9 0-1.5.6-1.5 1.5s.6 1.5 1.5 1.5h24.1c.7 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5h-3.7V62.3h4.2c11.5 0 17.1 0 21-3.9 3.9-3.9 3.9-9.5 3.9-21V23c-.1-13.2-4-17.1-17.5-17.1zM45.1 76.7H34.7V62.3h10.4v14.4zm29-39.3c0 10.6 0 15.9-3 18.9-3 3-8.3 3-18.9 3H19.8c-12 0-14.4-2.4-14.4-14.1V23c0-11.7 2.4-14.1 14.4-14.1h39.9c12 0 14.4 2.4 14.4 14.1v14.4z"
          fill={colors.PRIMARY_GREY_900}
        />
        <path
          d="M49.3 33.1h-8v-8c0-.9-.6-1.5-1.5-1.5s-1.5.6-1.5 1.5v8h-7.9c-.9 0-1.5.6-1.5 1.5s.6 1.5 1.5 1.5h7.9v8c0 .9.6 1.5 1.5 1.5s1.5-.6 1.5-1.5v-8h8c.9 0 1.5-.6 1.5-1.5s-.8-1.5-1.5-1.5z"
          fill={colors.PRIMARY_GREY_900}
        />
        <path
          d="M49.6 50.7c-.4 0-.9-.2-1.1-.7l-1.1-2-1.1 1.6c-.2.3-.6.5-1 .4-.4-.1-.7-.4-.7-.8l-1-9.9c0-.4.2-.7.5-.9.3-.2.7-.1 1 .1l7.9 6.1c.3.2.4.6.3 1-.1.4-.5.6-.9.7l-2 .1 1.1 2.1c.2.3.2.6.1 1-.1.3-.3.6-.6.8l-.9.5c-.1-.1-.3-.1-.5-.1zm-2.1-5.1 2.1 3.8.9-.5-2.1-3.8 3.1-.1-6.5-5.1.8 8.3 1.7-2.6zm4.7 0zm.2-.6z"
          fill={colors.PRIMARY_GREY_900}
        />
      </g>
    </symbol>
  )
}

const CreationSiteStandaloneContent = standalone(CreationSiteSymbol, ID)

export const CreationSite = styled(function CreationSiteStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="creationsite">
      <CreationSiteStandaloneContent />
    </illustration.Illustration>
  )
})`
  fill: none;
`

export const CreationSiteLightweight = styled(function CreationSiteComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="creationsite">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})`
  fill: none;
`

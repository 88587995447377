import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'made-in-france-boutique-illustration-icon'

export function MadeInFranceSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="M68.842 35.536c-.652.342-1.362.897-2.024 1.532l-.019.018a14.411 14.411 0 0 0-1.335 1.477c-.193.247-.04.62.245.724.95.348 1.799 1.303 2.29 3.513.723 3.347 1.085 5.494-.06 7.073-.398.714-.528 3.215-.14 4.035a.304.304 0 0 0 .225.16c1.427.28 2.395 1.087 2.687 2.248.241 1.01.06 2.653-2.41 4.484-.453.332-.804.664-1.154.995-.117.11-.234.221-.354.331-1.928 1.832-3.496 2.905-7.233.758-2.472-1.39-6.39-2.084-8.138-.884-.301.19-1.205.821-.964 3.158.06.632-.12 1.39-.543 1.895-.422.505-1.085.821-1.748.884-.603.063-1.145.063-1.687.063-2.23 0-4.34-.379-6.27-2.147a2.96 2.96 0 0 0-1.928-.758c-2.773-.063-6.812-.695-10.187-3.22-1.086-.822-1.568-2.212-1.146-3.475 2.526-8.256 3.312-14.248 2.417-17.787a.354.354 0 0 0-.013-.043c-.178-.462-3.535-9.012-9.516-11.285-1.688-.632-2.653-2.4-2.834-5.053-.06-1.263.664-2.4 1.748-2.842 4.1-1.642 6.812-1.706 8.138-.19a2.595 2.595 0 0 0 .625.515c.309.186.655.32 1.02.416.289.075.589.127.89.161.265.03.466-.239.4-.515a33.643 33.643 0 0 1-.411-1.984l-.005-.024c-.07-.396-.126-.748-.168-1.032-.18-1.074.422-2.147 1.386-2.526 1.387-.569 2.532-.569 3.436-.063.543.316.965.757 1.387 1.2.282.295.721.756.925.866.025.014.053.02.082.022.572.05.821-.149 1.645-.888.663-.632 1.688-1.58 3.255-2.211 1.386-.568 2.05-2.969 2.23-5.053.12-1.2 1.025-2.084 2.17-2.084 1.326.063 3.255.19 4.943.947 1.361.57 2.23 1.709 2.964 2.669l.213.277a.439.439 0 0 1 .032.048c.116.198.232.346.348.495.06.077.12.154.18.238 1.869 2.463 8.921 7.326 11.574 8.084.549.144 1.203.178 1.881.214h.004c.214.012.431.023.647.038 1.688.064 3.375.127 4.158 1.516.686 1.317.019 2.86-.26 3.502l-.042.098c-.665 1.453-.922 2.96-1.147 4.273l-.058.337-.011.07c-.298 1.874-.614 3.857-2.34 4.73z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M59.99 39.7c-.628.32-1.313.838-1.95 1.43l-.02.017a13.668 13.668 0 0 0-1.286 1.378c-.187.231-.039.58.236.677.915.324 1.734 1.216 2.207 3.278.698 3.124 1.046 5.128-.058 6.602-.383.666-.508 3-.135 3.765a.293.293 0 0 0 .218.15c1.376.262 2.31 1.014 2.59 2.097.234.944.06 2.477-2.324 4.186-.436.31-.774.62-1.112.93-.113.102-.225.205-.341.308-1.86 1.71-3.371 2.711-6.975.707-2.383-1.297-6.162-1.945-7.847-.825-.29.177-1.163.766-.93 2.947.058.59-.116 1.297-.523 1.769-.407.472-1.046.766-1.686.825-.581.059-1.104.059-1.627.059-2.15 0-4.185-.354-6.045-2.004a2.907 2.907 0 0 0-1.86-.707c-2.674-.06-6.569-.649-9.824-3.007-1.046-.766-1.511-2.063-1.104-3.242 2.436-7.705 3.193-13.298 2.33-16.602a.32.32 0 0 0-.012-.04c-.172-.43-3.409-8.41-9.177-10.533-1.627-.59-2.557-2.24-2.732-4.715a2.703 2.703 0 0 1 1.686-2.653c3.953-1.532 6.568-1.592 7.847-.177a2.478 2.478 0 0 0 .602.481c.298.174.632.299.985.388.278.07.567.118.858.15.254.029.448-.223.385-.48a30.714 30.714 0 0 1-.397-1.852l-.004-.022c-.068-.37-.122-.699-.162-.964a2.152 2.152 0 0 1 1.337-2.358c1.337-.53 2.441-.53 3.313-.059.523.295.93.708 1.337 1.12.272.276.696.706.892.81a.206.206 0 0 0 .079.02c.552.046.792-.14 1.586-.83.64-.59 1.628-1.473 3.139-2.063 1.337-.53 1.976-2.77 2.15-4.716.117-1.12.988-1.945 2.093-1.945 1.279.059 3.139.177 4.766.884 1.313.533 2.152 1.595 2.859 2.49l.205.26c.011.014.022.029.031.045.112.184.223.323.335.461.058.072.116.144.174.222 1.802 2.3 8.603 6.838 11.16 7.546.53.134 1.16.166 1.815.2h.003c.206.01.416.021.625.035 1.627.06 3.254.118 4.01 1.415.66 1.229.016 2.669-.251 3.268l-.003.005-.038.087c-.642 1.356-.89 2.761-1.107 3.988v.002l-.056.313-.01.065c-.288 1.749-.592 3.6-2.256 4.415z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
        />
      </g>
    </symbol>
  )
}

const MadeInFranceStandaloneContent = standalone(MadeInFranceSymbol, ID)

export const MadeInFrance = styled(function MadeInFranceStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="made-in-france">
      <MadeInFranceStandaloneContent />
    </illustration.Illustration>
  )
})``

export const MadeInFranceLightweight = styled(function MadeInFranceComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="made-in-france">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'devices-illustration-icon'

export function DevicesSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 35 28">
      <g id="devices" clipPath="url(#clip0_1943_153379)">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3252 0.403283L31.9937 2.4403L31.9941 2.44046C32.4679 2.58515 32.8482 2.70131 33.1482 2.82071C33.4568 2.94347 33.7154 3.08267 33.9299 3.29099C34.2724 3.62354 34.5014 4.05716 34.5846 4.52948C34.6366 4.82516 34.6074 5.11968 34.5372 5.44725C34.4688 5.76603 34.3531 6.15073 34.209 6.63013L30.5894 18.6706C30.4453 19.15 30.3296 19.5347 30.211 19.8382C30.089 20.15 29.9512 20.4113 29.745 20.628C29.4157 20.9744 28.9864 21.2069 28.5185 21.2919C28.2253 21.3452 27.9335 21.3162 27.6093 21.2457C27.2938 21.1771 26.9133 21.0609 26.4395 20.9161L21.7368 19.4796V20.8425C22.1595 20.984 22.4882 21.3284 22.6058 21.7656C22.6522 21.9383 22.652 22.1348 22.6518 22.3673V22.4858C22.652 22.9852 22.6521 23.3217 22.5746 23.6096C22.3651 24.3886 21.7554 24.994 20.9788 25.2014C20.6916 25.278 20.3558 25.2779 19.8531 25.2778H6.29875C5.79605 25.2779 5.46028 25.278 5.17309 25.2014C4.3964 24.994 3.78674 24.3886 3.57722 23.6096C3.49975 23.3216 3.49986 22.9852 3.50001 22.4858L3.50007 22.1138C3.50007 21.519 3.8854 21.0197 4.41505 20.8423V13.161C4.41505 12.6662 4.41505 12.2685 4.44144 11.9468C4.46862 11.6158 4.52582 11.3274 4.66183 11.0613C4.87878 10.6371 5.22447 10.2932 5.64846 10.0779C5.91378 9.94328 6.20144 9.88657 6.53253 9.8596C6.8549 9.83336 7.25353 9.83336 7.75061 9.83336L18.6337 9.83347C18.7262 9.83362 18.8149 9.83389 18.9001 9.83457C18.9391 9.83488 18.9773 9.8353 19.0147 9.83577L21.1753 2.64878L21.1804 2.63187C21.22 2.50026 21.2574 2.37583 21.2933 2.25837C21.31 2.20364 21.3264 2.15044 21.3426 2.09869C21.3638 2.03088 21.3846 1.96552 21.4051 1.90262C21.424 1.84439 21.4427 1.7883 21.4613 1.73425C21.4923 1.64428 21.5229 1.56002 21.5537 1.4812C21.6756 1.16945 21.8136 0.908125 22.0197 0.691315C22.3489 0.344995 22.7782 0.112475 23.2462 0.0274266C23.5393 -0.0258332 23.8312 0.00323185 24.1554 0.0737212C24.4698 0.142063 24.8488 0.2578 25.3205 0.401869L25.3252 0.403283Z"
          fill="currentColor"
        />
        <path
          id="Vector"
          d="M28.0711 5.19206L27.7002 6.41576C27.5519 6.90529 27.4777 7.15005 27.3261 7.30825C27.1927 7.44741 27.019 7.54074 26.8296 7.57487C26.6144 7.61366 26.3705 7.53974 25.8826 7.39189L23.4433 6.6527C22.9554 6.50485 22.7114 6.43093 22.554 6.27919C22.4154 6.14572 22.3227 5.97161 22.289 5.78184C22.2507 5.56609 22.3249 5.32133 22.4732 4.8318L22.844 3.6081M21.352 17.9482L21.3607 17.9508M18.2627 21.3065L23.4705 22.8847C24.4463 23.1804 24.9342 23.3282 25.3647 23.2507C25.7433 23.1824 26.0909 22.9957 26.3576 22.7174C26.6608 22.401 26.8092 21.9115 27.1058 20.9324L30.7081 9.04507C31.0048 8.06601 31.1532 7.57649 31.0766 7.14499C31.0092 6.76544 30.8237 6.41723 30.5467 6.15029C30.2317 5.84681 29.7438 5.69896 28.768 5.40326L22.1471 3.3969C21.1713 3.1012 20.6834 2.95335 20.253 3.03095C19.8743 3.0992 19.5268 3.28586 19.26 3.56417C18.9568 3.88058 18.8085 4.3701 18.5118 5.34915L16.2699 12.7474M21.7876 18.0802C21.7144 18.3216 21.4601 18.4581 21.2196 18.3852C20.979 18.3123 20.8433 18.0576 20.9164 17.8162C20.9896 17.5748 21.2439 17.4383 21.4844 17.5112C21.725 17.5841 21.8607 17.8388 21.7876 18.0802Z"
          fill="none"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Vector_2"
          d="M1.69172 23.625V15.7112C1.69172 14.7038 1.69172 14.2002 1.89094 13.8154C2.06617 13.477 2.34579 13.2018 2.68971 13.0294C3.08069 12.8334 3.59251 12.8334 4.61617 12.8334H15.2173C16.2409 12.8334 16.7528 12.8334 17.1437 13.0294C17.4877 13.2018 17.7673 13.477 17.9425 13.8154C18.1417 14.2002 18.1417 14.7038 18.1417 15.7112V23.625H13.2641C13.0405 23.625 12.9288 23.625 12.8236 23.6499C12.7303 23.6719 12.6412 23.7083 12.5594 23.7576C12.4672 23.8132 12.3882 23.8909 12.2301 24.0465L12.1728 24.1029C12.0147 24.2584 11.9357 24.3362 11.8435 24.3918C11.7617 24.4411 11.6725 24.4775 11.5793 24.4995C11.4741 24.5243 11.3624 24.5243 11.1388 24.5243H8.69461C8.47109 24.5243 8.35932 24.5243 8.25414 24.4995C8.16089 24.4775 8.07175 24.4411 7.98998 24.3918C7.89776 24.3362 7.81873 24.2584 7.66067 24.1029L7.60333 24.0465C7.44527 23.8909 7.36624 23.8132 7.27401 23.7576C7.19225 23.7083 7.1031 23.6719 7.00985 23.6499C6.90467 23.625 6.79291 23.625 6.56938 23.625H1.69172ZM1.69172 23.625C1.18699 23.625 0.777832 24.0277 0.777832 24.5243V24.8241C0.777832 25.3817 0.777832 25.6604 0.840112 25.8892C1.00912 26.5099 1.50179 26.9947 2.13255 27.161C2.36498 27.2223 2.64828 27.2223 3.21487 27.2223H16.6186C17.1852 27.2223 17.4685 27.2223 17.7009 27.161C18.3316 26.9947 18.8243 26.5099 18.9933 25.8892C19.0556 25.6604 19.0556 25.3817 19.0556 24.8241C19.0556 24.5453 19.0556 24.406 19.0245 24.2916C18.94 23.9812 18.6936 23.7388 18.3783 23.6557C18.262 23.625 18.1204 23.625 17.8371 23.625H17.2278"
          fill="none"
          stroke={colors.PRIMARY_GREY_900}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1943_153379">
          <rect width="34.6111" height="28" fill="white" />
        </clipPath>
      </defs>
    </symbol>
  )
}

const DevicesStandaloneContent = standalone(DevicesSymbol, ID)

export const Devices = styled(function DevicesStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="devices">
      <DevicesStandaloneContent />
    </illustration.Illustration>
  )
})``

export const DevicesLightweight = styled(function DevicesComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="devices">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

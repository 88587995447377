// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
export function shuffle<T>(array: T[]): T[] {
  for (let index = array.length - 1; index > 0; index--) {
    const randomIndex = Math.floor(Math.random() * (index + 1))
    ;[array[index], array[randomIndex]] = [array[randomIndex], array[index]]
  }

  return array
}

export function partition<T>(array: T[], predicate: (item: T) => boolean): [T[], T[]] {
  return array.reduce<[T[], T[]]>(
    (accumulator, item) => {
      if (predicate(item)) {
        accumulator[0].push(item)
      } else {
        accumulator[1].push(item)
      }
      return accumulator
    },
    [[], []],
  )
}

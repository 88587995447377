import * as api from '@owl-nest/api-client/next'

export type ProjectSearchParams = {
  lang: api.Lang
  limit: string
}

export async function search(
  params: ProjectSearchParams,
  options?: {
    withAccessToken?: boolean
  },
  qualifiers?: api.SearchQualifier,
): Promise<api.HttpResult<api.Pageable<api.ProjectList>>> {
  const querySearch = api.getQuerySearch({
    params,
    qualifiers: {
      ...qualifiers,
      sort: qualifiers?.sort ?? 'popular',
      status: qualifiers?.status ?? 'currently',
      quality_score__not: qualifiers?.quality_score__not ?? ['X'],
    },
    search: '',
    withAccessToken: options?.withAccessToken ?? false,
  })

  const response = await api.client('GET /search/projects', {
    getParams: querySearch.toString(),
    withToken: options?.withAccessToken ?? false,
  })

  return response.next((success) => success.body)
}

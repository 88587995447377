import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'recycled-materials-boutique-illustration-icon'

export function RecycledMaterialsSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="m45.308 64.417 4.444.124a9.508 9.508 0 0 0 4.862-1.147c1.467-.807 2.768-2.16 3.58-3.593L65 47.96c.78-1.396 1.143-2.979 1.085-4.604a9.4 9.4 0 0 0-1.488-4.628l-.977-1.597 2.487-1.36a.792.792 0 0 0 .393-.858.903.903 0 0 0-.707-.69L53.213 31.9c-.347-.055-.697.089-.871.391l-.068.118-4.186 11.455a.85.85 0 0 0 .277.934c.29.235.676.28.984.112l2.375-1.3 4.573 7.504-11.321-.281c-.47-.016-.847.349-.831.806l.294 11.903a.848.848 0 0 0 .267.603.93.93 0 0 0 .615.267l-.013.004z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M56.297 51.114s1.812 2.687 2.08 5.712c.178 2.019-.689 3.992-2.177 5.36-.691.64-1.553 1.306-2.348 1.584"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="m56.297 51.114-4.573-7.504-2.374 1.3a.887.887 0 0 1-.985-.11.85.85 0 0 1-.277-.935l4.186-11.455.068-.117c.174-.303.524-.447.871-.392l12.579 2.322c.35.069.637.347.707.69a.792.792 0 0 1-.393.859l-2.487 1.359.977 1.597a9.4 9.4 0 0 1 1.488 4.628c.058 1.625-.305 3.208-1.085 4.604l-6.804 11.841c-.813 1.434-2.114 2.786-3.581 3.593a9.508 9.508 0 0 1-4.862 1.147l-4.444-.124.014-.004a.93.93 0 0 1-.616-.267.848.848 0 0 1-.267-.603l-.294-11.903c-.016-.457.36-.822.83-.806l11.322.28zm0 0s1.812 2.687 2.08 5.712c.178 2.019-.689 3.992-2.177 5.36-.691.64-1.553 1.306-2.348 1.584"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeLinejoin="round"
        />
        <path
          d="m53.144 19.013-2.115-3.929a9.57 9.57 0 0 0-3.42-3.656c-1.429-.873-3.244-1.33-4.886-1.32h-13.61c-1.593.02-3.139.495-4.51 1.358-1.362.873-2.482 2.125-3.245 3.609l-.889 1.649-2.415-1.484a.784.784 0 0 0-.937.087.908.908 0 0 0-.241.96l4.288 12.106c.126.33.425.562.773.562h.135L34.04 26.87a.845.845 0 0 0 .667-.708.896.896 0 0 0-.396-.912L32 23.834l4.183-7.731 5.418 9.99c.222.418.725.563 1.111.32l10.113-6.207a.847.847 0 0 0 .386-.534.94.94 0 0 0-.077-.669l.01.01z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M36.184 16.103s1.41-2.92 3.883-4.666c1.651-1.164 3.786-1.396 5.708-.785.898.281 1.903.698 2.54 1.251"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="m36.184 16.103-4.182 7.73 2.308 1.417c.3.184.454.543.396.912a.845.845 0 0 1-.667.708l-11.967 2.085h-.135c-.348 0-.647-.233-.773-.562l-4.288-12.106a.908.908 0 0 1 .241-.96.784.784 0 0 1 .937-.087l2.415 1.484.889-1.65c.763-1.483 1.883-2.735 3.245-3.607 1.371-.864 2.917-1.34 4.51-1.358h13.61c1.642-.01 3.457.446 4.887 1.319a9.57 9.57 0 0 1 3.42 3.656l2.114 3.929-.01-.01a.94.94 0 0 1 .078.67.847.847 0 0 1-.386.533l-10.113 6.208c-.386.242-.889.097-1.11-.32l-5.42-9.991zm0 0s1.41-2.92 3.883-4.666c1.651-1.164 3.786-1.396 5.708-.785.898.281 1.903.698 2.54 1.251"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeLinejoin="round"
        />
        <path
          d="m10.28 30.67-2.328 3.805a9.621 9.621 0 0 0-1.443 4.804c-.038 1.68.477 3.488 1.307 4.912l6.804 11.841c.814 1.377 1.996 2.484 3.426 3.246a9.289 9.289 0 0 0 4.733 1.02l1.866-.052-.072 2.843a.79.79 0 0 0 .543.772c.332.11.714 0 .949-.27l8.29-9.785c.222-.274.273-.65.099-.953l-.068-.118-7.78-9.37a.84.84 0 0 0-.945-.225.894.894 0 0 0-.587.8l-.067 2.717-8.755.226 5.903-9.71c.248-.402.122-.912-.28-1.126l-10.407-5.695a.84.84 0 0 0-.653-.07.932.932 0 0 0-.538.402l.003-.013z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M16.252 46.883s-3.221.233-5.963-1.046c-1.829-.855-3.097-2.596-3.531-4.573-.207-.923-.35-2.005-.192-2.836"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="m16.252 46.883 8.755-.226.067-2.717a.894.894 0 0 1 .587-.8.84.84 0 0 1 .944.225l7.781 9.37.068.118c.174.302.123.68-.099.953l-8.29 9.785a.899.899 0 0 1-.949.27.79.79 0 0 1-.543-.772l.072-2.843-1.866.051a9.289 9.289 0 0 1-4.733-1.02c-1.43-.761-2.612-1.868-3.426-3.245L7.816 44.191c-.83-1.424-1.345-3.232-1.307-4.912a9.621 9.621 0 0 1 1.443-4.804l2.328-3.804-.003.013a.932.932 0 0 1 .538-.402.84.84 0 0 1 .653.07l10.407 5.695c.402.215.528.724.28 1.126l-5.903 9.71zm0 0s-3.221.233-5.963-1.046c-1.829-.855-3.097-2.596-3.531-4.573-.207-.923-.35-2.005-.192-2.836"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeLinejoin="round"
        />
        <path
          d="m41.701 19.429-4.182 7.73 2.308 1.417c.3.184.454.543.396.911a.845.845 0 0 1-.666.709L27.59 32.28h-.136c-.347 0-.647-.233-.772-.563l-4.289-12.105a.908.908 0 0 1 .242-.96.784.784 0 0 1 .937-.088l2.414 1.484.889-1.648c.763-1.485 1.883-2.736 3.245-3.609 1.372-.863 2.917-1.338 4.51-1.358h13.61c1.642-.01 3.458.446 4.887 1.32a9.57 9.57 0 0 1 3.42 3.656l2.115 3.929-.01-.01a.94.94 0 0 1 .077.67.847.847 0 0 1-.386.533L48.23 29.74c-.386.242-.888.097-1.11-.32L41.7 19.428zm0 0s1.41-2.92 3.883-4.666c1.651-1.164 3.786-1.397 5.708-.786.898.282 1.903.699 2.54 1.252M59.608 55.457l-4.53-7.31-2.363 1.274a.894.894 0 0 1-.978-.107.816.816 0 0 1-.273-.91l4.182-11.18.068-.114c.173-.296.521-.437.866-.383l12.497 2.24a.885.885 0 0 1 .701.67.77.77 0 0 1-.392.84l-2.474 1.33.968 1.555c.89 1.37 1.401 2.93 1.47 4.51a8.424 8.424 0 0 1-1.088 4.492l-6.785 11.56c-.81 1.4-2.106 2.721-3.566 3.511a9.62 9.62 0 0 1-4.834 1.128l-4.416-.112.013-.004a.929.929 0 0 1-.611-.26.817.817 0 0 1-.264-.587l-.27-11.607c-.015-.446.36-.802.828-.788l11.25.252zm0 0s1.795 2.617 2.056 5.566c.172 1.968-.693 3.895-2.174 5.23-.689.627-1.546 1.278-2.337 1.55M20.068 52.638l8.7-.204.062-2.65c.008-.343.238-.649.583-.779a.848.848 0 0 1 .938.222l7.751 9.153.068.114c.173.296.123.663-.096.93l-8.22 9.526a.902.902 0 0 1-.943.261.776.776 0 0 1-.542-.753l.066-2.773-1.854.047a9.401 9.401 0 0 1-4.706-1.004c-1.422-.745-2.6-1.827-3.41-3.171l-6.787-11.561c-.827-1.39-1.342-3.154-1.308-4.793a9.226 9.226 0 0 1 1.425-4.681l2.306-3.706-.003.013a.92.92 0 0 1 .534-.391.85.85 0 0 1 .65.069l10.354 5.574c.4.21.526.707.28 1.099l-5.848 9.458zm0 0s-3.201.22-5.928-1.032c-1.82-.837-3.083-2.537-3.519-4.467-.207-.9-.35-1.956-.196-2.766"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const RecycledMaterialsStandaloneContent = standalone(RecycledMaterialsSymbol, ID)

export const RecycledMaterials = styled(function RecycledMaterialsStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="recycled-materials">
      <RecycledMaterialsStandaloneContent />
    </illustration.Illustration>
  )
})``

export const RecycledMaterialsLightweight = styled(function RecycledMaterialsComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="recycled-materials">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'

/** call `onScrollable` when target transition from/to scrollable
 * (content overflow hidden behind scroll bar) */
export function useScrollableListener(
  target: React.RefObject<HTMLElement>,
  onScrollable: (scrollable: { x: boolean; y: boolean }) => void,
): void {
  const isScrollableRef = React.useRef<{ x: boolean; y: boolean }>({ x: false, y: false })

  React.useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  function onResize(): void {
    if (target.current === null) {
      return
    }

    const scrollableX = target.current.scrollWidth > target.current.clientWidth
    const scrollableY = target.current.scrollHeight > target.current.clientHeight

    if (isScrollableRef.current.x !== scrollableX || isScrollableRef.current.y !== scrollableY) {
      isScrollableRef.current = { x: scrollableX, y: scrollableY }
      onScrollable(isScrollableRef.current)
    }
  }
}

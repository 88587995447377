import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'upcycled-materials-boutique-illustration-icon'

export function UpcycledMaterialsSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          d="m44.272 39.672 3.75.104a8.062 8.062 0 0 0 4.102-.962c1.237-.677 2.335-1.81 3.02-3.013l5.742-9.931a7.301 7.301 0 0 0 .915-3.862 7.85 7.85 0 0 0-1.255-3.881l-.824-1.34 2.098-1.14a.663.663 0 0 0 .332-.72.76.76 0 0 0-.597-.579l-10.613-1.947a.71.71 0 0 0-.735.328l-.057.099-3.532 9.607a.71.71 0 0 0 .233.784c.244.197.57.234.83.093l2.005-1.09 3.858 6.293-9.552-.235c-.398-.013-.715.293-.702.676l.249 9.983a.71.71 0 0 0 .225.506c.138.136.333.22.52.224l-.012.003z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M53.544 28.515s1.528 2.254 1.755 4.79c.15 1.694-.582 3.35-1.837 4.495-.584.538-1.31 1.096-1.982 1.33"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="m53.544 28.515-3.858-6.294-2.004 1.091a.752.752 0 0 1-.83-.093.71.71 0 0 1-.234-.784l3.532-9.607.057-.099a.71.71 0 0 1 .735-.328l10.613 1.947a.76.76 0 0 1 .597.579.663.663 0 0 1-.332.72l-2.098 1.14.824 1.34a7.85 7.85 0 0 1 1.255 3.881 7.301 7.301 0 0 1-.915 3.862l-5.741 9.93c-.686 1.203-1.784 2.337-3.022 3.014a8.062 8.062 0 0 1-4.102.962l-3.749-.104.011-.003a.786.786 0 0 1-.52-.224.71.71 0 0 1-.224-.506l-.249-9.983c-.013-.383.304-.689.702-.676l9.552.235zm0 0s1.528 2.254 1.755 4.79c.15 1.694-.582 3.35-1.837 4.495-.584.538-1.31 1.096-1.982 1.33"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeLinejoin="round"
        />
        <path
          d="m52.09 60.602-1.784 3.295a8.047 8.047 0 0 1-2.885 3.067c-1.206.732-2.738 1.114-4.124 1.106H31.815a7.325 7.325 0 0 1-3.806-1.139 7.86 7.86 0 0 1-2.738-3.026l-.75-1.383-2.038 1.245a.665.665 0 0 1-.79-.074.759.759 0 0 1-.204-.805l3.619-10.153a.71.71 0 0 1 .652-.472h.114l10.097 1.75a.71.71 0 0 1 .562.593.75.75 0 0 1-.334.765l-1.948 1.188 3.53 6.484 4.571-8.38a.663.663 0 0 1 .937-.268l8.533 5.206a.71.71 0 0 1 .326.448.784.784 0 0 1-.066.561l.009-.008z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M37.78 63.043s1.19 2.448 3.276 3.913c1.394.976 3.195 1.171 4.817.659.758-.236 1.605-.586 2.143-1.05"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="m37.78 63.043-3.529-6.484L36.2 55.37a.75.75 0 0 0 .334-.765.71.71 0 0 0-.562-.594l-10.097-1.749h-.114a.71.71 0 0 0-.652.472l-3.619 10.153a.759.759 0 0 0 .204.805.665.665 0 0 0 .79.074l2.038-1.245.75 1.383a7.86 7.86 0 0 0 2.738 3.026 7.325 7.325 0 0 0 3.806 1.14h11.482c1.386.007 2.918-.375 4.124-1.107a8.047 8.047 0 0 0 2.885-3.067l1.785-3.295-.008.008a.784.784 0 0 0 .065-.561.71.71 0 0 0-.326-.448l-8.533-5.206a.663.663 0 0 0-.937.268l-4.572 8.38zm0 0s1.19 2.448 3.276 3.913c1.394.976 3.195 1.171 4.817.659.758-.236 1.605-.586 2.143-1.05"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeLinejoin="round"
        />
        <path
          d="m15.924 51.825-1.965-3.191a8.036 8.036 0 0 1-1.217-4.03c-.032-1.408.403-2.925 1.103-4.119l5.741-9.931a7.315 7.315 0 0 1 2.89-2.722 7.876 7.876 0 0 1 3.994-.855l1.574.043-.06-2.385a.664.664 0 0 1 .458-.647c.28-.093.602 0 .8.226l6.995 8.206c.187.23.23.546.084.8l-.057.099-6.566 7.858a.712.712 0 0 1-.796.19.75.75 0 0 1-.496-.671l-.056-2.279-7.387-.19 4.98 8.144c.21.337.104.765-.236.945l-8.78 4.776a.711.711 0 0 1-.552.059.785.785 0 0 1-.454-.338l.003.012z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="M20.963 38.227s-2.718-.195-5.031.877c-1.544.717-2.614 2.177-2.98 3.836-.174.774-.295 1.682-.162 2.379"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          d="m20.963 38.227 7.387.19.056 2.279a.75.75 0 0 0 .496.67.712.712 0 0 0 .796-.189l6.566-7.858.056-.099a.707.707 0 0 0-.083-.8l-6.995-8.206a.761.761 0 0 0-.8-.226.664.664 0 0 0-.459.647l.061 2.384-1.574-.042a7.876 7.876 0 0 0-3.993.855 7.315 7.315 0 0 0-2.891 2.722l-5.741 9.931c-.7 1.194-1.135 2.71-1.103 4.12a8.036 8.036 0 0 0 1.217 4.029l1.965 3.19-.003-.01a.785.785 0 0 0 .454.337.711.711 0 0 0 .551-.059l8.781-4.776c.34-.18.446-.608.236-.945l-4.98-8.144zm0 0s-2.718-.195-5.031.877c-1.544.717-2.614 2.177-2.98 3.836-.174.774-.295 1.682-.162 2.379"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeLinejoin="round"
        />
        <path
          d="m42.435 60.253-3.529-6.484 1.948-1.188a.75.75 0 0 0 .334-.764.71.71 0 0 0-.562-.594l-10.097-1.75h-.114a.709.709 0 0 0-.652.473l-3.619 10.152a.759.759 0 0 0 .204.806.665.665 0 0 0 .79.073l2.038-1.245.75 1.383a7.861 7.861 0 0 0 2.738 3.027 7.325 7.325 0 0 0 3.806 1.139h11.483c1.385.008 2.917-.375 4.123-1.107a8.046 8.046 0 0 0 2.885-3.067l1.785-3.295-.008.009a.785.785 0 0 0 .065-.562.71.71 0 0 0-.326-.447l-8.533-5.207a.663.663 0 0 0-.937.269l-4.572 8.379zm0 0s1.19 2.449 3.276 3.913c1.394.976 3.195 1.172 4.817.66.758-.237 1.605-.587 2.143-1.05M24.183 33.4l7.34.171.052 2.222a.73.73 0 0 0 .492.654c.29.109.6.027.791-.186l6.54-7.677.058-.096a.679.679 0 0 0-.082-.78L32.44 19.72a.764.764 0 0 0-.795-.22.651.651 0 0 0-.457.633l.055 2.325-1.564-.039a7.973 7.973 0 0 0-3.97.842c-1.201.625-2.194 1.532-2.879 2.66l-5.725 9.696c-.698 1.166-1.133 2.645-1.104 4.02a7.705 7.705 0 0 0 1.202 3.926l1.946 3.108-.003-.01a.776.776 0 0 0 .45.327.72.72 0 0 0 .549-.058l8.736-4.675a.64.64 0 0 0 .236-.921L24.183 33.4zm0 0s-2.702-.185-5.003.865c-1.535.702-2.6 2.128-2.968 3.747-.175.754-.296 1.64-.165 2.32M56.337 32.158l-3.822-6.13-1.994 1.067a.758.758 0 0 1-.825-.09.682.682 0 0 1-.23-.763l3.528-9.376.057-.096c.146-.248.44-.367.731-.322l10.545 1.879a.745.745 0 0 1 .591.563.645.645 0 0 1-.33.703L62.5 20.71l.817 1.305a7.531 7.531 0 0 1 1.24 3.783 7.03 7.03 0 0 1-.918 3.767l-5.725 9.696c-.684 1.174-1.777 2.281-3.009 2.944a8.156 8.156 0 0 1-4.078.946l-3.727-.094.011-.003a.786.786 0 0 1-.515-.218.683.683 0 0 1-.223-.493l-.228-9.735c-.012-.374.304-.672.699-.66l9.493.21zm0 0s1.514 2.194 1.735 4.668c.145 1.65-.585 3.267-1.835 4.387-.58.525-1.304 1.07-1.972 1.3"
          stroke="#232221"
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const UpcycledMaterialsStandaloneContent = standalone(UpcycledMaterialsSymbol, ID)

export const UpcycledMaterials = styled(function UpcycledMaterialsStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="upcycled-materials">
      <UpcycledMaterialsStandaloneContent />
    </illustration.Illustration>
  )
})``

export const UpcycledMaterialsLightweight = styled(function UpcycledMaterialsComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="upcycled-materials">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

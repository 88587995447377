import { SearchQualifier } from '../latest.ts'

const projectSearchExtraFields = {
  guests: ['main_image', 'main_tag', 'owner', 'partnerships', 'avatar_image'],
  users: ['user_role', 'main_image', 'main_tag', 'owner', 'partnerships', 'avatar_image'],
}

type GetQuerySearchProps = {
  params: { [s: string]: boolean | number | string }
  qualifiers: SearchQualifier
  search: string
  withAccessToken: boolean
}

export const getQuerySearch = ({
  search = '',
  qualifiers = {},
  params,
  withAccessToken,
}: GetQuerySearchProps): URLSearchParams => {
  const extraFields = withAccessToken ? projectSearchExtraFields.users : projectSearchExtraFields.guests
  const paramsUrl = new URLSearchParams()
  const request = search ? `${search} ${stringifyQualifiers(qualifiers)}` : `${stringifyQualifiers(qualifiers)}`
  paramsUrl.append('q', request)
  paramsUrl.append('extra_fields', extraFields.join(','))

  Object.keys(params).map((name) => {
    paramsUrl.append(name, params[name].toString())
  })

  return paramsUrl
}

function stringifyQualifiers(qualifiers: SearchQualifier): string {
  return Object.entries(qualifiers)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return (value as []).map((value: string) => `${key}:${value}`).join(' ')
      } else {
        return `${key}:${value}`
      }
    })
    .join(' ')
}

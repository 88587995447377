import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'tiktok-illustration-icon'

export function TiktokSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 90 80">
      <g>
        <path
          d="M81 19c-4.3 0-8.3-1.4-11.4-3.8-3.7-2.7-6.3-6.7-7.2-11.3-.3-1.1-.4-2.3-.4-3.5H49.7v51.1c0 4.8-3.2 8.9-7.6 10.4-1.3.4-2.7.6-4.1.5-1.8-.1-3.6-.6-5.1-1.5-3.2-1.9-5.4-5.3-5.4-9.2-.1-6.1 4.9-11.1 11.1-11.1 1.2 0 2.4.2 3.5.6V29c-1.2-.2-2.3-.3-3.5-.3-6.8 0-13.1 2.8-17.7 7.8-3.4 3.8-5.5 8.6-5.8 13.7-.4 6.7 2.1 13 6.9 17.6.7.7 1.4 1.3 2.2 1.9 4.1 3.1 9.1 4.8 14.4 4.8 1.2 0 2.4-.1 3.5-.3 4.9-.7 9.5-3 13.1-6.5 4.4-4.3 6.9-10 6.9-16.1l-.1-27c2.1 1.6 4.4 2.9 6.9 4C72.8 30.2 76.8 31 81 31V19c.1 0 0 0 0 0z"
          fill="currentColor"
        />
        <path
          d="M37.1 51.4zM76.3 21.7c-17.9-1.9-18-16.7-18-17.4V3.1H43.7l.3 31.3V37l.1 11 .1 7.1c0 5.4-4.5 9.8-10.1 9.8S24 60.5 24 55.1s4.5-9.8 10.1-9.8c.7 0 1.3.1 1.9.2.8.2 1.6.4 2.3.7V32.5c-.8-.2-1.5-.3-2.3-.4-.9-.1-1.8-.1-2.7-.1C19.9 32 8.9 42.7 8.9 55.8c0 13.1 11 23.8 24.4 23.8 13.5 0 24.4-10.6 24.4-23.7l.6-24.9c7.2 5.2 17.5 5.1 17.9 5.1h1.1V21.8l-1-.1zm-1.3 12c-3.1-.1-11.9-.9-17.1-6L56 25.8l-.4 20.3-.2 9.7c0 11.8-9.9 21.4-22.1 21.4s-22.1-9.6-22.1-21.4 9.9-21.4 22.1-21.4c.9 0 1.8.1 2.7.2v8.6c-.6-.2-1.2-.2-1.9-.2-6.9 0-12.4 5.4-12.4 12.1 0 6.7 5.6 12.1 12.4 12.1s12.4-5.4 12.4-12.1l-.2-16.6v-2.8L46 5.5h10c.4 3.9 2.9 16.2 19 18.4v9.8z"
          fill={colors.PRIMARY_GREY_900}
        />
      </g>
    </symbol>
  )
}

const TiktokStandaloneContent = standalone(TiktokSymbol, ID)

export const Tiktok = styled(function TiktokStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="tiktok">
      <TiktokStandaloneContent />
    </illustration.Illustration>
  )
})`
  fill: none;
`

export const TiktokLightweight = styled(function TiktokComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} name="tiktok">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})`
  fill: none;
`

import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'short-supply-chain-boutique-illustration-icon'

export function ShortSupplyChainSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 80 80">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 33c-1.439 0-9-6.235-9-12.756 0-5.105 4.029-9.244 9-9.244 4.97 0 9 4.14 9 9.244 0 6.52-7.563 12.756-9 12.756zM60.5 65c-1.439 0-9-6.235-9-12.756 0-5.105 4.029-9.244 9-9.244 4.97 0 9 4.14 9 9.244 0 6.52-7.563 12.756-9 12.756z"
          fill={colors.SECONDARY_GREEN_LIGHT}
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m40.24 24.01 7.352 1.151c5.075.795 6.472 7.306 2.147 9.98L24.886 50.452c-4.321 2.648-2.924 9.158 2.123 9.948l7.351 1.152"
          stroke={colors.SECONDARY_GREEN_LIGHT}
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M56.5 68c-1.439 0-9-6.235-9-12.756 0-5.105 4.029-9.244 9-9.244 4.97 0 9 4.14 9 9.244 0 6.52-7.562 12.756-9 12.756z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M59.5 55a3 3 0 1 0-6 0 3 3 0 0 0 6 0zM19.5 36c-1.439 0-9-6.235-9-12.756 0-5.105 4.028-9.244 9-9.244 4.97 0 9 4.14 9 9.244 0 6.52-7.562 12.756-9 12.756z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M22.5 23a3 3 0 1 0-6 0 3 3 0 0 0 6 0z"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.987 22h7.155c4.939 0 7.235 6.216 3.524 9.527L27.336 50.5c-3.712 3.284-1.416 9.5 3.497 9.5h7.154"
          stroke={colors.PRIMARY_BLACK}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
  )
}

const ShortSupplyChainStandaloneContent = standalone(ShortSupplyChainSymbol, ID)

export const ShortSupplyChain = styled(function ShortSupplyChainStandaloneComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="short-supply-chain">
      <ShortSupplyChainStandaloneContent />
    </illustration.Illustration>
  )
})``

export const ShortSupplyChainLightweight = styled(function ShortSupplyChainComponent(
  props: illustration.SpecificIllustrationProps,
) {
  return (
    <illustration.Illustration {...props} name="short-supply-chain">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

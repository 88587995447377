import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../../constants/colors'
import { lightweight, standalone, SymbolProps } from '../../../spritesheetUtils'
import * as illustration from '../../Illustration'

const ID = 'cart-boutique-illustration-icon'

export function CartSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 87 77">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.462 23.081 54.184 5.206c-1.82-2.278-5.066-2.562-7.253-.635L29.765 19.696l-8.06-.705c-6.503-.57-11.928 4.757-11.333 11.125l2.676 28.664c.463 4.956 4.484 8.886 9.543 9.33l44.53 3.895c5.059.443 9.702-2.73 11.018-7.53L85.75 36.71c1.692-6.168-2.726-12.356-9.228-12.924l-8.06-.706Zm-9.325-.816L50.034 10.87l-10.943 9.643 20.046 1.753Z"
          fill={colors.SECONDARY_GREEN_LIGHT}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.336 10.35a4.38 4.38 0 0 1 6.326 0l17.115 17.608h9.046c6.381 0 11.115 5.518 10.016 11.43l-5.504 29.589C72.477 73.59 68.279 77 63.319 77H15.68c-4.96 0-9.157-3.41-10.016-8.023L.16 39.386c-1.1-5.911 3.635-11.429 10.016-11.429h9.046L36.336 10.35ZM52.59 27.958 39.5 14.491 26.41 27.958h26.18Zm-42.414 4.658c-3.214 0-5.742 2.81-5.155 5.967l5.504 29.589c.454 2.442 2.649 4.17 5.155 4.17h47.64c2.506 0 4.701-1.728 5.155-4.17l5.504-29.59c.588-3.157-1.94-5.966-5.155-5.966H10.175Z"
          fill={colors.SECONDARY_MINT}
        />
      </g>
    </symbol>
  )
}

const CartStandaloneContent = standalone(CartSymbol, ID)

export const Cart = styled(function CartStandaloneComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} viewBox="0 0 87 77" name="cart">
      <CartStandaloneContent />
    </illustration.Illustration>
  )
})``

export const CartLightweight = styled(function CartComponent(props: illustration.SpecificIllustrationProps) {
  return (
    <illustration.Illustration {...props} viewBox="0 0 87 77" name="cart">
      {lightweight(ID)}
    </illustration.Illustration>
  )
})``

import * as React from 'react'
import styled from 'styled-components'

import { t } from '@owl-nest/localize'
import * as entities from '@ulule/entities'
import * as plume from '@ulule/owl-kit-components/next'

import * as style from '../style'

const THUMBNAIL_SET = {
  '1x': `${process.env.ASSET_PREFIX}/assets/img/homepage/lead-magnet-thumbnail.png`,
  '2x': `${process.env.ASSET_PREFIX}/assets/img/homepage/lead-magnet-thumbnail@2x.png`,
  '3x': `${process.env.ASSET_PREFIX}/assets/img/homepage/lead-magnet-thumbnail@3x.png`,
}

type LeadMagnetProps = {
  leadMagnet: entities.homepage.LeadMagnet | null
}

export function LeadMagnet({ leadMagnet }: LeadMagnetProps): React.ReactElement {
  if (leadMagnet === null) {
    return <></>
  }

  return (
    <Section>
      <LeftPath />
      <FloatingCard>
        <VideoWrapper>
          <Thumbnail
            alt={t('Play video')}
            onClick={() => window.open(leadMagnet.ctaUrl)}
            src={THUMBNAIL_SET['1x']}
            srcSet={`${THUMBNAIL_SET['1x']} 1x, ${THUMBNAIL_SET['2x']} 2x, ${THUMBNAIL_SET['3x']} 3x`}
          />
          <VideoPlayer onClick={() => window.open(leadMagnet.ctaUrl)} width="47" />
        </VideoWrapper>
        <TextWrapper>
          <plume.styles.heading.L>{leadMagnet.title}</plume.styles.heading.L>
          <plume.styles.copy.L>{leadMagnet.description}</plume.styles.copy.L>
          <VideoCTA>
            <plume.Link darkened tinted uppercase href={leadMagnet.ctaUrl}>
              {t('Play video')}
            </plume.Link>
          </VideoCTA>
        </TextWrapper>
      </FloatingCard>
      <RightPath />
    </Section>
  )
}

const FloatingCard = styled.div`
  background-color: ${plume.COLORS.PRIMARY_GREY_000};
  border-radius: 6px;
  box-shadow: ${plume.EFFECTS.SHADOW_4};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 800px;
  padding: 32px 24px;
  position: relative;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    flex-direction: row;
    padding: 30px;
  }
`

const Section = styled(style.Section)`
  overflow: hidden;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin-top: 0;
    padding-top: 45px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: min-content;
  order: 1;

  ${plume.styles.copy.L} {
    font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.L.semiBold, so this is just by anticipation.
  }

  ${plume.styles.copy.M} {
    margin-top: 5px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin-left: 15px;
    order: 2;
  }
`

const Thumbnail = styled.img`
  align-self: flex-end;
  height: 84px;
  margin: 20px auto 0;
  position: relative;
  width: 84px;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    cursor: pointer;
    height: 116px;
    left: 0;
    margin: 0;
    width: 116px;
  }
`

const VideoCTA = styled(plume.styles.copy.M)`
  bottom: 50px;
  left: 44%;
  display: inline-block;
  position: absolute;

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    left: 46%;
  }

  @media screen and ${plume.BREAKPOINTS.MOBILE_L} {
    left: 48%;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    position: relative;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
  }
`

const VideoWrapper = styled.div`
  cursor: pointer;
  margin: auto;
  max-width: fit-content;
  order: 2;
  position: relative;
  left: -55px;
  white-space: nowrap;

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    left: -65px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    cursor: initial;
    left: 0;
    margin: 0;
    max-width: initial;
    order: 1;
  }
`

/**
 * VECTORS
 */

const LeftPath = styled((props: React.SVGAttributes<SVGSymbolElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="955" height="151" fill="none" {...props}>
      <path
        stroke={plume.COLORS.PRIMARY_SAND_700}
        strokeDasharray="2 4"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M952.948 63.837c-18.21-19.94-65.756-48.325-110.263-2.33-37.082 38.32-81.455 23.86-107.918 5.956m0 0c-.902-.61-1.782-1.224-2.641-1.84-6.646-4.766-12.559-16.217-3.931-19.545 7.767-2.025 8.093 6.498 6.572 21.385Zm0 0c-.364 3.555-.922 7.674-1.705 12.423-13.98 84.757-252.584 21.075-349.562-33.808C275-6 49.2-51.7-40 149.5"
      />
    </svg>
  )
})`
  display: none;
  transition-duration: 200ms;
  transition-property: right;
  transition-timing-function: ease-in-out;

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    display: block;
    position: absolute;
    right: 80%;
    top: 0px;
    z-index: -1;
  }

  @media screen and (min-width: 1250px) {
    right: 77%;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    right: 74%;
  }

  @media screen and (min-width: 1650px) {
    right: 70%;
  }

  @media screen and (min-width: 1920px) {
    right: 66.5%;
  }

  @media screen and (min-width: 2250px) {
    right: 64.5%;
  }

  @media screen and ${plume.BREAKPOINTS.DESKTOP} {
    right: 63%;
  }
`

const RightPath = styled((props: React.SVGAttributes<SVGSymbolElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="930" height="261" fill="none" {...props}>
      <path
        stroke={plume.COLORS.PRIMARY_SAND_700}
        strokeDasharray="2 4"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M399.979 77.74c13.521 4.51 34.52-12.74 42.322-34.813 21.329-60.345 83.905-41.762 106.814-27.464C628.5 65.007 700.299 303.6 943.499 252M399.979 77.739a117.392 117.392 0 0 1-3.039-1.06c-7.685-2.799-16.464-12.233-9.051-17.763 6.934-4.043 9.544 4.077 12.09 18.823Zm0 0c.608 3.522 1.18 7.639 1.705 12.424 9.374 85.388-177.924 149.601-399.363 37.956"
      />
    </svg>
  )
})`
  display: none;
  transition-duration: 200ms;
  transition-property: left;
  transition-timing-function: ease-in-out;

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    display: block;
    left: 78%;
    position: absolute;
    top: 60px;
    z-index: -1;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    left: 70%;
  }

  @media screen and (min-width: 1920px) {
    left: 67%;
  }

  @media screen and (min-width: 2250px) {
    left: 65.5%;
  }

  @media screen and ${plume.BREAKPOINTS.DESKTOP} {
    left: 65%;
  }
`

const VideoPlayer = styled((props: React.SVGAttributes<SVGSymbolElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 47 47" {...props}>
      <circle cx="23.5" cy="23.5" r="23.5" fill="#fff" opacity=".9" />
      <path
        clipRule="evenodd"
        d="m33.291 23.5-15.014 9.14V14.36l15.014 9.14Z"
        fill={plume.COLORS.PRIMARY_BLUE_700}
        fillRule="evenodd"
      />
    </svg>
  )
})`
  bottom: 0;
  left: -28px;
  position: relative;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    cursor: pointer;
    left: -34px;
    top: 0;
  }
`

import * as React from 'react'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

type CarouselProps = {
  children: React.ReactNode
  className?: string
  $scrollOffset?: number
  $verticalOnMobile?: boolean
}

export function Carousel({
  children,
  className,
  $scrollOffset = 280,
  $verticalOnMobile = false,
}: CarouselProps): React.ReactElement {
  const $carouselRef = React.useRef<HTMLDivElement | null>(null)

  const [canScrollRight, setCanScrollRight] = React.useState(true)
  const [canScrollLeft, setCanScrollLeft] = React.useState(false)
  const [contentIsScrollable, setContentAsScrollable] = React.useState(true)

  plume.hooks.useScrollableListener($carouselRef, (scrollable) => {
    setContentAsScrollable(scrollable.x)
  })

  plume.hooks.useScrollListener($carouselRef, () => {
    if ($carouselRef.current && $carouselRef.current?.clientWidth !== $carouselRef.current?.scrollWidth) {
      setCanScrollLeft($carouselRef.current.scrollLeft > 0)
      setCanScrollRight(
        $carouselRef.current.scrollWidth - $carouselRef.current.scrollLeft !== $carouselRef.current.clientWidth,
      )
    }
  })

  plume.hooks.useIsoLayoutEffect(() => {
    if ($carouselRef.current) {
      setContentAsScrollable($carouselRef.current?.clientWidth !== $carouselRef.current?.scrollWidth)
    }
  }, [$carouselRef.current])

  plume.hooks.useIsoLayoutEffect(() => {
    if ($carouselRef.current) {
      $carouselRef.current.scrollLeft = 0
    }
  }, [children])

  const handleScrollLeft = (): void => {
    if ($carouselRef.current) {
      $carouselRef.current.scrollLeft -= $scrollOffset
    }
  }

  const handleScrollRight = (): void => {
    if ($carouselRef.current) {
      $carouselRef.current.scrollLeft += $scrollOffset
    }
  }

  return (
    <Wrapper>
      {contentIsScrollable && (
        <ControlWrapper>
          <ControlButtons>
            <plume.SliderButton direction="left" disabled={!canScrollLeft} onClick={handleScrollLeft} />
            <plume.SliderButton direction="right" disabled={!canScrollRight} onClick={handleScrollRight} />
          </ControlButtons>
        </ControlWrapper>
      )}
      <CarouselWrapper className={className} ref={$carouselRef} verticalOnMobile={$verticalOnMobile}>
        {children}
      </CarouselWrapper>
    </Wrapper>
  )
}

const ControlWrapper = styled.div`
  width: 89%;
  max-width: 1330px;
  position: relative;
  margin: auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    flex-direction: column;
  }
`

export const CarouselWrapper = styled.div<{ verticalOnMobile?: boolean }>`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ verticalOnMobile = false }) => {
    if (verticalOnMobile) {
      return css`
        @media not screen and ${plume.BREAKPOINTS.TABLET} {
          flex-direction: column;
        }
      `
    }
  }};
`

export const ControlButtons = styled.div`
  bottom: 0;
  display: flex;
  gap: 16px;
  left: 0;
  margin-left: auto;
  margin-top: 24px;
  width: fit-content;

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    bottom: initial;
    left: initial;
    margin: 0;
    position: absolute;
    right: 0;
    top: -80px;
  }
`
